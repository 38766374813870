import React, { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	Paper,
	LinearProgress,
} from "@mui/material";
import { styled } from '@mui/material/styles';

// My components
import { useTranslation } from "react-i18next";

import { HorizontalSelectorLandmarks } from "./HorizontalSelectorLandmarks";

const PIXELS_TO_CENTER_LANDMARK_DELIMITATION = 7;

export default function WallSelector(props) {
	const {
		getUserStores,
		getWallsAisles,
		getWallImage,
		getLandmarks,
		getAllLandmark,
		getCoords,
		isLoadingUserStores,
		isLoadingAislesData,
		isLoadingCoordsInfo,
		isLoadingWallLandmarks,
		selectedStore,
		setSelectedStore,
		aislesData,
		userStores,
		coordsInfo,
		selectedClient,
		setWallLandmarks,
		wallLandmarks,
		selectedAisle,
		setAisleState,
		selectedLandmark,
		setLandmarkState,
		// selectedProduct,
		linkReceived,
		setProductState,
		dateState,
		setDateState,
		setLinkState,
		getAislePromotions,
		aisleLandmarks,
		setAisleLandmarkState,
		modalIntersected
	} = props;
	const { t } = useTranslation();
	const [supplier, setSupplierState] = useState(null);

	const [aislesList, setAislesListState] = useState(null);
	const [linearNames, setLinearNamesState] = useState(null);
	const [coordsState, setCoordsState] = useState(null);
	const [coordsState2, setCoordsState2] = useState(null);

	const [selectedCategories, setCategoryState] = useState(null);
	const [categoryFilters, setCategoryFilters] = useState({});

	const [productValue, setProductValue] = useState(null);

	useEffect(() => {
		if (linkReceived?.selectedStore || linkReceived?.storeName) {
			const linkProduct = linkReceived['product'];
			const linkDate = linkReceived['date'];
			const linkSelectedStore = linkReceived['selectedStore'];
			const linkSelectedCategory = linkReceived['selectedCategory'];

			setDateState(new Date(linkDate));
			if (linkSelectedStore) {
				setSelectedStore(linkSelectedStore);
			}
			setCategoryState(linkSelectedCategory);
			setProductState(linkProduct);
		}
	}, [linkReceived]);

	useEffect(() => {
		if (selectedClient) {
			getUserStores(selectedClient.client_id);
			setSupplierState(selectedClient?.supplier_id);
		}
	}, [getUserStores, selectedClient]);

	useEffect(() => {
		if (selectedStore) {
			getWallsAisles(selectedStore['store_code'], supplier, true, dateState, 'yyyy/MM/dd');
		}
	}, [selectedStore, supplier, dateState,]);

	useEffect(() => {
		if (selectedStore) {
			if (aislesData && Object.keys(aislesData).length > 0) {
				setAislesListState(aislesData.aisles_list);
				setCategoryFilters(aislesData.categories_filter.map(c => { return { category_name: c } }));
				getAllLandmark(selectedStore.store_code, supplier, aislesData.session);
				if (aislesData.client_linear_names) {
					setLinearNamesState(aislesData.client_linear_names)
				}
			}
		}
	}, [aislesData]);

	useEffect(() => {
		if (selectedStore
			&& selectedAisle && Object.keys(selectedAisle).length > 0
			&& aislesData && Object.keys(aislesData).length > 0) {
			setWallLandmarks([]);
			setLandmarkState({});
			getWallImage(selectedStore['store_code'], supplier, selectedAisle['session'], selectedAisle['code']);
			getLandmarks(selectedStore['store_code'], selectedAisle['code'], selectedAisle['session']);
			getCoords(selectedStore.store_id, supplier, selectedAisle['session'], selectedAisle['code']);
			getAislePromotions(selectedStore.store_code, supplier, selectedAisle.code, selectedAisle.session);
		}
	}, [selectedAisle]);

	const handleSelectCategory = (newCategory) => {
		setCategoryState(newCategory);
		if (newCategory['category_name'] === 'ALL') {
			setAislesListState(aislesData['aisles_list']);
		} else {
			const filteredAisles = [];
			aislesData['aisles_list'].forEach(element => {
				if (element['category_name'].includes(newCategory['category_name'])) {
					filteredAisles.push(element)
				}
			});
			setAislesListState(filteredAisles);
		}
	}

	const sortAislesByName = () => {
		let sortedAisles = [...aislesList].sort((a, b) => {
			return a.code.localeCompare(b.code, undefined, {
				numeric: true,
				sensitivity: 'base'
			})
		})
		setAislesListState(sortedAisles);
	}

	const sortAislesByAlerts = () => {
		let sortedAisles = [...aislesList].sort((a, b) => {
			if (a['alerts'][0] < b['alerts'][0])
				return 1;
			return -1;
		})
		setAislesListState(sortedAisles);
	}

	const sortLandmarksByName = () => {
		let sortedLandmarks = [...coordsState].sort((a, b) => {
			return a.landmark.localeCompare(b.landmark, undefined, {
				numeric: true,
				sensitivity: 'base'
			})
		})
		setCoordsState2(sortedLandmarks);
	}

	const sortLandmarksByAlerts = () => {
		let sortedLandmarks = [...coordsState].sort((a, b) => {
			if (a.lInfo.alertCount < b.lInfo.alertCount)
				return 1;
			return -1;
		})
		setCoordsState2(sortedLandmarks);
	}

	const countLabelTotalAndAlerts = (value) => {
		let info = {
			alertCount: 0,
			total: 0
		}
		if (coordsInfo && coordsInfo.coords && Object.keys(coordsInfo.coords)) {
			coordsInfo.coords.forEach(label => {
				if (label.landmark === value) {
					if (label.stockout) {
						info.alertCount++;
					}
					info.total++;
				}
			})
		}
		return info;
	}

	const set_landmark = (coord, landmarkInfo, landmark_sequence, x_middle, next_landmark_position, is_last_scanned_landmark) => {

		if (landmarkInfo[coord['landmark']]) {
			// set position for the landmark's begining (landmark_postion) and ending (next_landmark_position) that are not in scanned_landmarks
			if ((!coord['landmark_label'] && !coord['promotion']) || is_last_scanned_landmark) {
				landmarkInfo[coord['landmark']].landmark_position = Math.min(x_middle, landmarkInfo[coord['landmark']].landmark_position)
				landmarkInfo[coord['landmark']].next_landmark_position = Math.max(next_landmark_position, landmarkInfo[coord['landmark']].next_landmark_position)
				landmarkInfo[coord['landmark']].pixel_x = landmarkInfo[coord['landmark']].landmark_position
			} else { // set position for the landmark's begining (landmark_postion) and ending (next_landmark_position) in scanned_landmarks
				landmarkInfo[coord['landmark']].landmark_position = x_middle
				landmarkInfo[coord['landmark']].next_landmark_position = next_landmark_position
				landmarkInfo[coord['landmark']].pixel_x = x_middle
			}

			// if the item is not a landmark or promotion, add it to the total count of products, stock out, price difference and spread to fill (if it applies)
			// the count increases in total_in_stock if the item doesn't have stock out
			if (!coord['landmark_label'] && !coord['promotion']) {
				landmarkInfo[coord['landmark']].total++;
				landmarkInfo[coord['landmark']].total_in_stock = coord.stockout ? landmarkInfo[coord['landmark']].total_in_stock : landmarkInfo[coord['landmark']].total_in_stock + 1;
				landmarkInfo[coord['landmark']].total_stock_out = coord.stockout ? landmarkInfo[coord['landmark']].total_stock_out + 1 : landmarkInfo[coord['landmark']].total_stock_out;
				landmarkInfo[coord['landmark']].total_price_difference = coord.price_difference ? landmarkInfo[coord['landmark']].total_price_difference + 1 : landmarkInfo[coord['landmark']].total_price_difference;
				landmarkInfo[coord['landmark']].total_spread_to_fill = coord.spread_to_fill ? landmarkInfo[coord['landmark']].total_spread_to_fill + 1 : landmarkInfo[coord['landmark']].total_spread_to_fill;
			}
		} else if (coord['landmark']) { // landmarkInfo initialization
			landmarkInfo[coord['landmark']] = {
				session: coord['session'],
				aisle: coord['aisle'],
				landmark: coord['landmark'],
				pixel_x: x_middle,
				pixel_z: 1,

				total: coord['landmark_label'] || coord['promotion'] ? 0 : 1,
				total_in_stock: coord.stockout || coord['landmark_label'] || coord['promotion'] ? 0 : 1,
				total_stock_out: coord.stockout ? 1 : 0,
				total_price_difference: coord.price_difference ? 1 : 0,
				total_spread_to_fill: coord.spread_to_fill ? 1 : 0,
				landmark_position: x_middle,
				next_landmark_position: next_landmark_position
			}
			if (coord['landmark']) {
				landmark_sequence.push(coord['landmark'])
			}
		}
	}

	useEffect(() => {
		if (selectedAisle && coordsInfo && Object.keys(coordsInfo)) {
			let newWallLandmarks = [];
			if (wallLandmarks && wallLandmarks.length) {
				wallLandmarks.forEach(l => {
					let lInfo = countLabelTotalAndAlerts(l.landmark);
					newWallLandmarks.push({ ...l, lInfo });
				})

				setCoordsState(newWallLandmarks);
			}
			else if (wallLandmarks && !wallLandmarks.length && coordsInfo.coords && Object.keys(coordsInfo.coords)) {

				let newLandmarks = [];
				let seenLands = [];
				// variables to calculate landmarks
				const landmarkInfo = {};
				const landmark_sequence = [];
				let isLastScannedLandmark = false;
				for (let i = 0; i < coordsInfo.coords.length; i++) {
					const coords = coordsInfo.coords;
					const coord = coordsInfo.coords[i];
					if (!seenLands.includes(coord.landmark)) {
						newLandmarks.push({ landmark: coord.landmark, session: coord.session, aisle: coord.aisle, })
						seenLands.push(coord.landmark)
					}

					const x_middle = (coord['facing_top_left_x'] + (coord['facing_bottom_right_x'] - coord['facing_top_left_x']) / 2);
					// position for the next item, for the landmark delimitation
					// if it's the last landmark, it uses the position for the last product as the ending delimitation
					let next_landmark_position = x_middle
					if (coords[i + 1] && coord['landmark_label'] && coords[i + 1]['landmark_label']) {
						next_landmark_position = (coords[i + 1]['facing_top_left_x'] + (coords[i + 1]['facing_bottom_right_x'] - coords[i + 1]['facing_top_left_x']) / 2);
					} else if (wallLandmarks.length && coord['landmark'] === wallLandmarks[wallLandmarks.length - 1]['landmark']) {
						isLastScannedLandmark = true
					}
					// x_middle and next_landmark_position + PIXELS_TO_CENTER_LANDMARK_DELIMITATION to center the landmark delimitation on the landmark label
					set_landmark(coord, landmarkInfo, landmark_sequence, x_middle + PIXELS_TO_CENTER_LANDMARK_DELIMITATION, next_landmark_position + PIXELS_TO_CENTER_LANDMARK_DELIMITATION, isLastScannedLandmark);

				}
				newLandmarks.forEach(l => {
					let lInfo = countLabelTotalAndAlerts(l.landmark);
					newWallLandmarks.push({ ...l, lInfo });
				})
				let newWallLandmarksInfo = Object.values(landmarkInfo);
				if (newWallLandmarksInfo.length) {
					newWallLandmarksInfo = newWallLandmarksInfo.sort((a, b) => b.landmark.localeCompare(a.landmark, undefined, { numeric: true, sensitivity: 'base' }));
					setWallLandmarks(newWallLandmarksInfo);
				}

				setCoordsState(newWallLandmarks);
				setCoordsState2(newWallLandmarksInfo);
				const currentSelection = modalIntersected.landmark?.landmarkName
				let foundLandmark = newWallLandmarksInfo.find(x => x.landmark === currentSelection)
				if (foundLandmark) {
					setLandmarkState(foundLandmark);
				}

			}
		}
	}, [selectedAisle, wallLandmarks, coordsInfo,]);

	useEffect(() => {
		if (productValue && Object.keys(productValue).length > 0
			&& aislesData && Object.keys(aislesData).length > 0
		) {
			let productAisle = aislesData.aisles_list.find(a => a.code === productValue.aisle);
			if (productAisle) {
				setAisleState(productAisle);
				setProductState(productValue);
			}
		}
	}, [productValue,]);

	useEffect(() => {
		let landmarksDataTemp = {};
		let returnList = [];

		if (wallLandmarks?.length > 0) {
			wallLandmarks.forEach(l => {
				if (l.landmark) {
					landmarksDataTemp[l.landmark] = { stock: 0, zero_stock: 0, stockout: 0, spread_to_fill: 0, price_difference: 0 };
				}
			});
			if (coordsInfo?.coords?.length > 0) {
				coordsInfo.coords.forEach(coord => {
					if (coord?.landmark && landmarksDataTemp.hasOwnProperty(coord.landmark)) {
						if (coord.stockout) landmarksDataTemp[coord.landmark].stockout++;
						else if (coord.spread_to_fill) landmarksDataTemp[coord.landmark].spread_to_fill++;
						else if (coord.price_difference) landmarksDataTemp[coord.landmark].price_difference++;
						else landmarksDataTemp[coord.landmark].stock++;
					}
				});
			}
			Object.keys(landmarksDataTemp).forEach(l => {
				let l_data = { ...landmarksDataTemp[l] };
				l_data.total = l_data.stock + l_data.stockout + l_data.price_difference;
				landmarksDataTemp[l] = l_data;
			});
			wallLandmarks.forEach(l => returnList.push({ ...l, 'data': landmarksDataTemp[l.landmark] }));
		}

		setAisleLandmarkState(returnList);
	}, [coordsInfo,]);

	return (
		<Grid item container
			direction={'row'}
			spacing={{ xs: 1, md: 1 }}
		>
			<Grid item xs={12}>
				<Paper elevation={0} sx={{ px: 1, }}>
					<HorizontalSelectorLandmarks
						modalIntersected={modalIntersected}
						selectedStore={selectedStore}
						selectedLandmark={selectedLandmark}
						setLandmarkState={setLandmarkState}

						coordsState={coordsState2}
						isLoadingWallLandmarks={isLoadingWallLandmarks}

						coordsInfo={coordsInfo}
						isLoadingCoordsInfo={isLoadingCoordsInfo}

						sortLandmarksByName={sortLandmarksByName}
						sortLandmarksByAlerts={sortLandmarksByAlerts}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
}

const StyledPaper = styled(props => {
	return (
		<Paper
			elevation={1}
			{...props}
		/>
	)
}
)(({ theme }) => {
	return ({
		width: '100%',
		background: theme.palette.type === 'light' ? 'white' : theme.palette.paper.main,
		'& .MuiPaper-root': {
			backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.paper.main,
			color: 'white',
		},
		'& .MuiInputLabel-root': {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
		},
		'& .MuiOutlinedInput-root': {
			color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
			'& fieldset': {
				borderColor: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
			},
		},
	})
}
);

import * as React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DigitalShelf from "../../../redux/containers/digitalShelf/DigitalShelf";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShelfModal(props) {

	return (
		<Dialog
			BackdropProps={{ invisible: true }}
			open={props.open}
			maxWidth={"xl"}
			fullWidth
			PaperProps={{
				sx: {
					maxHeight: 700,
					position: 'absolute',
					top: 15,
					mt: 0,
				}
			}}
			TransitionComponent={Transition}
			keepMounted
			onClose={props.handleClose}
			aria-describedby="alert-dialog-slide-description"
			style={{ overflow: "hidden" }}
		>
			<DialogTitle>{"Digital Shelf"}</DialogTitle>
			<DialogContent style={{ overflow: "hidden" }}>
				{props.open ? <DigitalShelf modalIntersected={props.modalIntersected} /> : null}

			</DialogContent>
			{/* <DialogActions> */}
			{/* <Button onClick={() => { console.log("asdfasdf") }}>Disagree</Button> */}
			{/* </DialogActions> */}
		</Dialog>
	);
}

import {
	Badge,
	Chip,
	Grid,
	LinearProgress,
	Paper,
	Tooltip,
	Typography,
	useTheme
} from "@mui/material";

import { useTranslation } from 'react-i18next';

import React from "react";

export function HorizontalSelectorLandmarks(props) {

	const {
		selectedStore,
		selectedLandmark,
		setLandmarkState,

		coordsState, //wallLandmarks for sorting
		isLoadingWallLandmarks,

		coordsInfo,
		isLoadingCoordsInfo,

		sortLandmarksByName,
		sortLandmarksByAlerts,
		modalIntersected
	} = props;
	const { t } = useTranslation();
	const theme = useTheme();


	return (
		<Grid item container>
			{!isLoadingWallLandmarks && !isLoadingCoordsInfo ?
				(
					coordsState && (coordsState.length > 1) && coordsInfo && (Object.keys(coordsInfo).length > 1)
						?
						<Grid item container xs={12} sx={{ mb: 1 }}>
							<Grid item xs={12}>
								<Paper component="ul" elevation={0}
									sx={{
										display: "flex",
										justifyContent: "left",
										flexWrap: "nowrap",
										listStyle: "none",
										padding: theme.spacing(0.5),
										margin: 0,
										overflow: "auto",
										maxWidth: "100%",
										p: 2,
										backgroundColor: '#1A242D'
									}}
								>
									{coordsState.map(item => {
										let landmarkCountInfo = item.lInfo;
										return (
											<li key={item.landmark}>
												<Tooltip placement='bottom'
													title={
														<> Total labels detected: {landmarkCountInfo ? landmarkCountInfo.total : 0}<br />
															Total alerts detected: {landmarkCountInfo ? landmarkCountInfo.alertCount : 0}</>
													}>
													<Badge badgeContent={landmarkCountInfo ? landmarkCountInfo.alertCount : 0} color="secondary" >
														<Chip
															label={item['landmark']}
															variant={'contained'}
															onClick={() => (setLandmarkState(item))}
															sx={{ mx: 1, px: 0.7, borderRadius: 1, color: 'white' }}
															style={{
																backgroundColor: (selectedLandmark && Object.keys(selectedLandmark).length > 0)
																	&& (item.landmark === selectedLandmark.landmark)
																	? '#F40D42'
																	: '#17181A'
															}} />
													</Badge>
												</Tooltip>
											</li>
										)
									})
									}
								</Paper>
							</Grid>
						</Grid>
						: null

				)
				:
				<Grid item container xs={12} alignContent='center' justifyContent='center'>
					<Typography sx={{ m: 1 }}>Loading Landmarks Information</Typography>
					<LinearProgress sx={{ width: '100%', m: 1 }} color="secondary" />
				</Grid>
			}
		</Grid>
	);
}

import { Grid, Typography, } from "@mui/material";

import Wall from '../../../../redux/containers/digitalShelf/Wall';
import WallSelector from '../../../../redux/containers/digitalShelf/WallSelector';
import { useTranslation } from "react-i18next";
import React, {useEffect} from "react";
import {setAisleState} from "core/slices/wall";

export default function DigitalShelf(props) {
    const { t } = useTranslation();
    
    const {
        getWallImage,
        getLandmarks,
        getAllLandmark,
        getCoords,
        setWallLandmarks,
        setLandmarkState,
        // selectedProduct,
        getAislePromotions,
        setAisleState,
    } = props;
    
    useEffect( () => {
        getAllLandmark(null, null, null);
        setWallLandmarks([]);
        setLandmarkState({});
        setAisleState(props.modalIntersected.aisle);
        getWallImage('HD0121', null, 'null', props.modalIntersected.aisle);
        getLandmarks('HD0121', props.modalIntersected.aisle, null);
        getCoords('HD0121', null, null, props.modalIntersected.aisle);
        getAislePromotions('HD0121', null, props.modalIntersected.aisle, null);
        return () => {
            setWallLandmarks([]);
            setLandmarkState({});
            setAisleState({});
        }
    }, [props.modalIntersected]);
    
    return (
      <React.Fragment>
          <WallSelector modalIntersected={props.modalIntersected}/>
          <Wall modalIntersected={props.modalIntersected}/>
      </React.Fragment>
    );
}

import { useRef, useEffect, Suspense, Fragment, useState } from "react";
import * as THREE from "three";
import * as ThreeMeshUI from "three-mesh-ui";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { flatPolygonTo3DGeometry, getAisleBayCoords, getHDCoords, simplifyToParallelogram, simplifyToRectangle } from '../home/map';
import Box from '../home/Boxes';
import TextCanvas from '../home/text';
import RobotoFont from '../../../assets/fonts/Roboto-Medium-msdf.json';
import RobotoFontTexture from '../../../assets/fonts/Roboto-Medium.png';
import BackgroundImage from '../../../assets/images/blackbg.jpeg';
import { getRandomColor } from '../../utils/colors';
// import BackgroundImage from '../../../assets/images/whitebg.jpeg';

const Picking = () => {
    const mountRef = useRef(null);
    const handler = () => {

        const currentRef = mountRef.current;
        const { clientWidth: width, clientHeight: height } = currentRef;

        let cubes,
            geom,
            range = 50,
            mouseVector;

        let containerWidth = width;
        let containerHeight = height;

        // Set up renderer, scene and camera
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.autoClear = false;
        renderer.setClearColor(0x000000, 0.0);
        renderer.setSize(width, height);
        currentRef.appendChild(renderer.domElement);

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000);
        scene.add(camera);
        const pos = { x: 12, y: 7, z: 100 }
        camera.position.set(pos.x, pos.y, pos.z);
        camera.lookAt(new THREE.Vector3(0, 0, 10));


        // Add some cubes to the scene
        geom = new THREE.BoxGeometry(5, 5, 5);

        cubes = new THREE.Object3D();
        scene.add(cubes);

        for (let i = 0; i < 100; i++) {
            let grayness = Math.random() * 0.5 + 0.25,
                mat = new THREE.MeshBasicMaterial(),
                cube = new THREE.Mesh(geom, mat);
            mat.color.setRGB(grayness, grayness, grayness);
            cube.position.set(range * (0.5 - Math.random()), range * (0.5 - Math.random()), range * (0.5 - Math.random()));
            cube.rotation.set(Math.random(), Math.random(), Math.random())
            cube.grayness = grayness;
            cubes.add(cube);
        }

        // Axes
        const axesHelper = new THREE.AxesHelper(2);
        scene.add(axesHelper);
        const size = 20;
        const divisions = size;
        const gridHelper = new THREE.GridHelper(size, divisions);
        scene.add(gridHelper);

        // Picking stuff
        const raycaster = new THREE.Raycaster();
        mouseVector = new THREE.Vector2();

        // User interaction
        window.addEventListener('mousemove', onMouseMove, false);
        window.addEventListener('resize', onWindowResize, false);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.zoomSpeed = 0.5;

        // And go!
        animate();


        function onMouseMove(event) {
            const rect = currentRef.getBoundingClientRect();
            mouseVector.x = ((event.clientX - rect.left) / containerWidth) * 2 - 1;
            mouseVector.y = -((event.clientY - rect.top) / containerHeight) * 2 + 1;

            raycaster.setFromCamera(mouseVector.clone(), camera);
            let intersects = raycaster.intersectObjects(cubes.children);

            cubes.children.forEach(function (cube) {
                cube.material.color.setRGB(cube.grayness, cube.grayness, cube.grayness);
            });

            for (var i = 0; i < intersects.length; i++) {
                var intersection = intersects[i],
                    obj = intersection.object;

                obj.material.color.setRGB(1.0 - i / intersects.length, 0, 0);
            }
        }


        function onWindowResize(e) {
            containerWidth = currentRef.clientWidth;
            containerHeight = currentRef.clientHeight;
            renderer.setSize(containerWidth, containerHeight);
            camera.aspect = containerWidth / containerHeight;
            camera.updateProjectionMatrix();
        }


        function animate() {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        }

    }
    useEffect(() => {
        handler();
    });

    return (
        <>
            <div
                id="container"
                className='Contenedor3D'
                ref={mountRef}
                style={{ width: "100%", height: "100vh" }}
            >
            </div>

        </>
    );
};

export default Picking;


const COLORS = {
    primary: '#1a242d',
    secondary: '#FF0266',
    success: '#46bf83',
    info: '#0070B7',
    warning: '#ffa400',
    error: '#fe2d38',
    slate: '#64748b',
    gray: '#6b7280',
    zinc: '#71717a',
    neutral: '#737373',
    stone: '#78716c',
    red: '#ef4444',
    orange: '#f97316',
    amber: '#f59e0b',
    yellow: '#eab308',
    lime: '#84cc16',
    green: '#22c55e',
    emerald: '#10b981',
    teal: '#14b8a6',
    cyan: '#06b6d4',
    sky: '#0ea5e9',
    blue: '#3b82f6',
    indigo: '#6366f1',
    violet: '#8b5cf6',
    purple: '#a855f7',
    fuchsia: '#d946ef',
    pink: '#ec4899',
    rose: '#f43f5e',
};

const CATEGORY_COLOR = {
    1: COLORS['teal'],
    2: COLORS['orange'],
    3: COLORS['yellow'],
    4: COLORS['lime'],
}

export function colorBasedOnTheme(theme, object) {
    let isDarkTheme = theme.palette.type === 'dark' ? true : false;
    let color = '';
    
    switch (object) {
      // Color
        case 'color':
            color = isDarkTheme ? 'primary.contrastText' : 'common.dark';
            break;
        case 'color-darkTheme':
            color = 'primary.contrastText';
            break;
      // Background
        case 'bgcolor-darkTheme':
            color = isDarkTheme ? 'paper.main' : 'primary.main';
            break;
      // case 'bgcolor-white':
      //   color = isDarkTheme ? 'paper.main' : 'common.white';
      //   break;
      // Effects
        case 'bgcolor-hover':
            color = isDarkTheme ? 'rgba(98, 98, 130, 0.1)' : 'rgba(0, 0, 0, 0.04)';
            break;
        case 'bgcolor-hover-darkTheme':
            color = 'rgba(98, 98, 130, 0.1)';
            break;
        default:
            break;
    }
    
    return color;
}

function applyShadeToHexColor(hexColor, shadeAmount) {
    // Check if shadeAmount is within the range [-100, 100]
    if (shadeAmount < -100 || shadeAmount > 100) {
        throw new Error('shadeAmount must be in the range of -100 to 100');
    }

    // Remove the '#' character if it's present in the hexColor value
    hexColor = hexColor.replace('#', '');

    // Convert the hexadecimal color to RGB values
    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);

    // Calculate the shade factor to apply
    const shadeFactor = 1 - (Math.abs(shadeAmount) / 100);

    // Apply the shade to the color
    const newR = Math.floor(r * shadeFactor);
    const newG = Math.floor(g * shadeFactor);
    const newB = Math.floor(b * shadeFactor);

    // Convert the new RGB values back to hexadecimal format
    const newHexColor = `#${(newR < 16 ? '0' : '') + newR.toString(16)}${(newG < 16 ? '0' : '') + newG.toString(16)}${(newB < 16 ? '0' : '') + newB.toString(16)}`;

    return newHexColor;
}

function getRandomValueFromObject(obj) {
    // Get all keys (properties) of the object into an array
    const keys = Object.keys(obj);

    // Generate a random index based on the length of the keys array
    const randomIndex = Math.floor(Math.random() * keys.length);

    // Use the random index to select a key
    const randomKey = keys[randomIndex];

    // Return the value associated with the random key
    const randomValue = obj[randomKey];

    return randomValue;
}

const transformCategoryToColor = (category) => {
    return CATEGORY_COLOR[category] ?? applyShadeToHexColor(getRandomValueFromObject(COLORS), 25);
}

const getRandomColor = (shade = 25) => {
    // return applyShadeToHexColor(getRandomValueFromObject(COLORS), shade);
    return "#eeefee"
    // return "#cdcecb"
}


export { COLORS, transformCategoryToColor, getRandomColor };

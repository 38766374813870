import React, { useEffect, useRef } from "react";
import * as THREE from "three";

function InteractiveCubes() {
    const containerRef = useRef();

    const [sceneRef, setScene] = React.useState(null);
    const [cameraRef, setCamera] = React.useState(null);
    const [raycasterRef, setRaycaster] = React.useState(null);
    const [rendererRef, setRendererRef] = React.useState(null);
    const [intersected, setIntersected] = React.useState(null);
    const [initializated, setInitialized] = React.useState(false);
    const pointer = new THREE.Vector2();

    useEffect(() => {
        // Initialize Three.js scene when the component mounts
        init();
        // animate();

        // Clean up Three.js scene when the component unmounts
        return () => {
            // Release resources, remove event listeners, etc.
        };
    }, []);

    useEffect(() => {
        if (intersected) {
            console.log("INTERSECTED", intersected);
        }
    }, [intersected]);

    useEffect(() => {
        if (rendererRef && initializated) {
            console.log("RENDERER REF", rendererRef, cameraRef);
            animate();
        }
    }, [rendererRef, initializated]);

    function init() {
        console.log("INIT");
        // Initialize Three.js components
        const camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.1, 100);
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xf0f0f0);

        const light = new THREE.DirectionalLight(0xffffff, 3);
        light.position.set(1, 1, 1).normalize();
        scene.add(light);

        const geometry = new THREE.BoxGeometry();

        for (let i = 0; i < 2000; i++) {
            const object = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff }));

            object.position.x = Math.random() * 40 - 20;
            object.position.y = Math.random() * 40 - 20;
            object.position.z = Math.random() * 40 - 20;

            object.rotation.x = Math.random() * 2 * Math.PI;
            object.rotation.y = Math.random() * 2 * Math.PI;
            object.rotation.z = Math.random() * 2 * Math.PI;

            object.scale.x = Math.random() + 0.5;
            object.scale.y = Math.random() + 0.5;
            object.scale.z = Math.random() + 0.5;

            scene.add(object);
        }

        const raycaster = new THREE.Raycaster();

        let renderer = new THREE.WebGLRenderer({ antialias: true });
        setRendererRef(renderer);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        containerRef.current.appendChild(renderer.domElement);

        window.addEventListener("resize", onWindowResize(renderer));
        document.addEventListener("mousemove", onPointerMove);

        setRaycaster(raycaster);
        setCamera(camera);
        setScene(scene);
        setInitialized(true);
    }

    const onWindowResize = (renderer) => () => {
        console.log();
        cameraRef.aspect = window.innerWidth / window.innerHeight;
        cameraRef.updateProjectionMatrix();
        rendererRef ? rendererRef.setSize(window.innerWidth, window.innerHeight) : renderer?.setSize(window.innerWidth, window.innerHeight);
    }

    function onPointerMove(event) {
        pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
        pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
    }

    function animate() {
        requestAnimationFrame(animate);
        render();
    }

    function render() {
        cameraRef.lookAt(sceneRef.position);
        cameraRef.updateMatrixWorld();

        raycasterRef.setFromCamera(pointer, cameraRef);
        const intersects = raycasterRef.intersectObjects(sceneRef.children, false);

        if (intersects.length > 0) {
            if (intersected !== intersects[0].object) {
                if (intersected) intersected.material.emissive.setHex(intersected.currentHex);
                const newIntersected = intersects[0].object;
                newIntersected.currentHex = newIntersected.material.emissive.getHex();
                newIntersected.material.color.setRGB(20, 0, 0);
                newIntersected.material.emissive.setHex(0xff0000);
                setIntersected(newIntersected);
            }
        } else {
            if (intersected) intersected.material.emissive.setHex(intersected.currentHex);
            setIntersected(null);
        }

        rendererRef.render(sceneRef, cameraRef);
    }

    return (
        <div>
            <div ref={containerRef}></div>
        </div>
    );
}

export default InteractiveCubes;

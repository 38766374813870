import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import { reducer as sessionReducer } from './slices/session';
import { reducer as wallReducer } from './slices/wall';

export default function _createStore(services) {
  return createStore(
    combineReducers({
      session: sessionReducer,
      wall: wallReducer
    }),
    applyMiddleware(thunk.withExtraArgument({ services })),
  );
}

import { useEffect } from "react";

import {
	CardContent,
	Divider,
	Grid,
	Typography, Box, Image, CircularProgress
} from '@mui/material';
import { useTranslation } from "react-i18next";
import { getConsolidatedTranslations } from 'core/utils/parsers';
import Carousel from 'react-material-ui-carousel'
//import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";




export default function CustomCardContent({
	selectedProduct, selectedStore, dateState,
	misplacedSignedImages,
	loadingMisplacedSignedImages,
	getMisplacedProductSignedImages }) {

	const { t } = useTranslation();

	useEffect(() => {
		if (selectedProduct && selectedProduct?.misplaced_product) {
			getMisplacedProductSignedImages(selectedStore.store_code, selectedProduct.blob)
		}
	}, [selectedProduct])

	return (
		<CardContent sx={{ width: '100%', p: 1 }}>
			{selectedProduct?.texts ?
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
					<Box sx={{ display: 'flex', gap: 0, justifyContent: "start", alignItems: 'flex-start', width: '100%', }}>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Typography variant="body" color="text.primary">
								{getConsolidatedTranslations(t, selectedStore?.chain_name, 'price', 'Walls', 'Price')}: ${selectedProduct?.price}
							</Typography>
							<Typography color="text.primary" sx={{ fontSize: 13 }}>
								<li> {getConsolidatedTranslations(t, selectedStore?.chain_name, 'ean', 'Walls', 'Barcode')}: {selectedProduct?.ean} </li>
								<li> {getConsolidatedTranslations(t, selectedStore?.chain_name, 'item', 'Walls', 'SKU')}: {selectedProduct?.item} </li>
								<li> {t('cws_app.general.sel_category', 'Category')}: {selectedProduct?.name_category ?? '-'} </li>
								{selectedProduct?.landmark ? <li> {getConsolidatedTranslations(t, selectedStore?.chain_name, 'landmark', 'Walls', 'Landmark')}: {selectedProduct?.landmark} </li> : null}
							</Typography>
							<Typography fontSize={13} color="text.secondary" >
								{t('cws_app.Walls.seen_on_day', 'Seen on day')}: {dateState.toLocaleDateString()}
							</Typography>

						</Box>

						{
							selectedProduct &&
								selectedProduct?.misplaced_product ?

								!loadingMisplacedSignedImages && misplacedSignedImages ?
									<Carousel id='carousel' height={100} sx={{ width: '150px', m: 0, p: 0 }} navButtonsAlwaysInvisible fullHeightHover={false} >
										{misplacedSignedImages.map((item, idx) => {
											return (
												<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
													<img key={idx} src={item} alt='' style={{ maxHeight: 100, maxWidth: 140, borderRadius: '5px' }} />
												</Box>
											)
										})}
									</Carousel> :
									<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '150px', mt: 3, p: 0, gap: 1 }}>
										<CircularProgress />
										<Typography fontSize={10}>Loading...</Typography>
									</Box>
								: null
						}
					</Box>

				</Box>


				: selectedProduct?.data ?
					<Grid container direction="row" justifyContent="center" alignItems="flex-start">
						<Grid item xs={12}>
							<Typography color="text.primary" sx={{ fontSize: 14 }}>
								<li> Total: {selectedProduct?.data.total} </li>
								<li> {t('cws_app.Walls.stock')}: {selectedProduct?.data.stock} </li>
								<li> {t('cws_app.Walls.stockout')}: {selectedProduct?.data.stockout} </li>
								<li> {t('cws_app.Walls.price_difference')}: {selectedProduct?.data.price_difference} </li>
								<li> {t('cws_app.Walls.spread_to_fill')}: {selectedProduct?.data.spread_to_fill} </li>
							</Typography>
						</Grid>
						<Grid item> <Divider /> </Grid>
						<Grid item xs={12}>
							<Typography color="text.primary" sx={{ fontSize: 14 }}>
								{getConsolidatedTranslations(t, selectedStore?.chain_name, 'landmark', 'Walls', 'landmark')}: {selectedProduct.landmark_type}
							</Typography>
						</Grid>
						<Grid container direction="column">
							<Typography variant="body2" color="text.secondary" alignSelf="flex-end">
								{t('cws_app.Walls.seen_on_day')}: {dateState.toLocaleDateString()}
							</Typography>
						</Grid>
					</Grid>
					:
					<Grid container direction="row" justifyContent="center" alignItems="flex-start">
						<Typography color="text.primary" sx={{ fontSize: 14 }}>
							<li> {getConsolidatedTranslations(t, selectedStore?.chain_name, 'store_catalog', 'Walls', 'store_catalog')}: {selectedProduct.catalog} </li>
							<li> {t('cws_app.Walls.start_date')}: {selectedProduct?.start_date} </li>
							<li> {t('cws_app.Walls.end_date')}: {selectedProduct?.end_date} </li>
						</Typography>
					</Grid>
			}
		</CardContent >
	)
}



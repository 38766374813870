import { createReducer } from './utils';
import landmarks from './hardcodeData/landmark.json';
import wallData31E from './hardcodeData/31E/wallData.json';
import coords31E from './hardcodeData/31E/coords.json';
import wallData37E from './hardcodeData/37E/wallData.json';
import coords37E from './hardcodeData/37E/coords.json';
import wallData37W from './hardcodeData/37W/wallData.json';
import coords37W from './hardcodeData/37W/coords.json';
import wallData38E from './hardcodeData/38E/wallData.json';
import coords38E from './hardcodeData/38E/coords.json';

import {
    WALL_REQUEST,
    WALL_AVAILABLE,
    WALL_UNAVAILABLE,
    SNACK_SET,
    DIGITAL_SHELF_AVAILABLE_EXCEL,
} from './constants';

import { handleAvailable, handleRequest, handleUnavailable } from '../reducerHandlers';

const hardCodedData = {
    'landmarks': landmarks,
    '31-E': {
        'walldata': wallData31E,
        'coords': coords31E
    },
    '37-E': {
        'walldata': wallData37E,
        'coords': coords37E
    },
    '37-W': {
        'walldata': wallData37W,
        'coords': coords37W
    },
    '38-E': {
        'walldata': wallData38E,
        'coords': coords38E
    }
}

const defaultState = {
    loadingAisles: false,
    loadingWallImageData: false,
    loadingWallLandmarksData: false,
    loadingLandmarkData: false,
    loadingCoordsInfo: false,
    loadingSearchResults: false,
    loadingAislePromotions: false,
    aislesData: {},
    wallImageData: {},
    wallLandmarksData: {},
    landmarkData: {},
    coordsInfo: {},
    searchResults: [],
    selectedAisle: null,
    selectedStore: null,
    selectedLandmark: null,
    selectedDate: new Date(),
    selectedProduct: null,
    excelLandmarks: [],
    loadingLandmarksExcel: false,
    aislePromotions: [],
    aisleLandmarks: [],
    misplacedSignedImages: [],
    loadingMisplacedSignedImages: false

};

export const reducer = createReducer(defaultState, {
    [WALL_REQUEST]: handleRequest,
    [WALL_AVAILABLE]: handleAvailable,
    [WALL_UNAVAILABLE]: handleUnavailable,
    [DIGITAL_SHELF_AVAILABLE_EXCEL]: handleAvailableExcel,
});

export function setWallLandmarks(newWallLandmarks) {
    return async (dispatch, _, __) => {
        const loadingName = 'loadingWallLandmarksData';
        const keyState = 'wallLandmarksData'
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: keyState,
                data: newWallLandmarks,
                loadingName: loadingName
            },
        });
    }
}

export function getWallsAisles(store, supplier_id, alerts_info, date) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingAisles';
        const keyState = 'aislesData'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getWallsAisles(store, supplier_id, alerts_info, date);
            let aislesData = response.aisles;
            if (aislesData === undefined) {
                alert('Sorry, there are no aisles available at this store');
            }
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: aislesData,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getWallImage(store, supplier_id, session, aisle, lang) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingWallImageData';
        const keyState = 'wallImageData'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            //const response = await dataSource.getWallImage(store, supplier_id, session, aisle, lang);
            console.log("asdfasdfasdfasdfas", hardCodedData[aisle], aisle)
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: hardCodedData[aisle].walldata,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getLandmarks(store, aisle_code, session) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingWallLandmarksData';
        const keyState = 'wallLandmarksData'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            //const response = await dataSource.getLandmarks(store, aisle_code, session);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: [],
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getAllLandmark(store, supplier_id, session) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingLandmarkData';
        const keyState = 'landmarkData'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            //const response = await dataSource.getLandmark(store, supplier_id, session);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: hardCodedData.landmarks,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getCoords(store_id, supplier_id, session, aisle, lang, supplier_info) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingCoordsInfo';
        const keyState = 'coordsInfo'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            //const response = await dataSource.getCoords(store_id, supplier_id, session, aisle, lang, supplier_info);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: hardCodedData[aisle].coords,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getMisplacedProductSignedImages(store, blobs) {
    const loadingName = 'loadingMisplacedSignedImages';
    const keyState = 'misplacedSignedImages'

    return async (dispatch, _, { services: { dataSource } }) => {
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getMisplacedProductSignedImages(store, blobs);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getSearchResults(store, supplier_id, aisle_filter, product, days, limit) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingSearchResults';
        const keyState = 'searchResults'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getSearchResults(store, supplier_id, aisle_filter, product, days, limit);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response.results,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    }
}

export function setSelectedStore(store) {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'selectedStore',
                data: store,
            },
        });
    };
}

export function setAisleState(aisle) {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'selectedAisle',
                data: aisle,
            },
        });
    };
}

export function setLandmarkState(landmark) {
    
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'selectedLandmark',
                data: landmark,
            },
        });
    };
}

export function setDateState(date) {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'selectedDate',
                data: date,
            },
        });
    };
}

export function setProductState(product) {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'selectedProduct',
                data: product,
            },
        });
    };
}

function handleAvailableExcel(state, { payload: { loadingName, excelData, excelFileName, canFormatData = false } }) {
    const newState = {
        ...state,
        [loadingName]: false,
    };
    

    return newState
}


export function getExcelLandmarks(store, supplier_id) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingLandmarksExcel';
        const keyState = 'excelLandmarks'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getLandmarksExcel(store, supplier_id);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function getDigitalShelfExcel({ excelData, excelFileName }) {
    return async (dispatch, _, { services: { __ } }) => {
        const loadingName = 'loadingLandmarksExcel'
        const keyState = 'excelFileMessage'

        dispatch({ type: WALL_REQUEST, payload: { loadingName } });
        try {
            dispatch({
                type: DIGITAL_SHELF_AVAILABLE_EXCEL,
                payload: { loadingName, excelData, excelFileName },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState, loadingName, defaultState: { 'message': 'error' } },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function clearCoordsState() {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'coordsInfo',
                data: {},
            },
        });
    };
}

export function clearWallImageData() {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'wallImageData',
                data: {},
            },
        });
    };
}

export function getAislePromotions(store, supplier_id, aisle_code, session) {
    return async (dispatch, _, { services: { dataSource } }) => {
        const loadingName = 'loadingAislePromotions';
        const keyState = 'aislePromotions'
        dispatch({ type: WALL_REQUEST, payload: { loadingName: loadingName } });
        try {
            const response = await dataSource.getAislePromotions(store, supplier_id, aisle_code, session);
            dispatch({
                type: WALL_AVAILABLE,
                payload: {
                    keyState: keyState,
                    data: response,
                    loadingName: loadingName
                },
            });
        } catch (error) {
            console.log('error: ', error);
            dispatch({
                type: WALL_UNAVAILABLE,
                payload: { error, keyState: keyState, loadingName: loadingName },
            });
            const snack = {
                open: true,
                message: 'There was an error',
                severity: 'error',
            };
            dispatch({ type: SNACK_SET, payload: { snack } });
        }
    };
}

export function setAisleLandmarkState(l) {
    return (dispatch) => {
        dispatch({
            type: WALL_AVAILABLE,
            payload: {
                keyState: 'aisleLandmarks',
                data: l,
            },
        });
    };
}

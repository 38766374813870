import { aisles, bayAisles, HD0121_points } from './constants'
import { transformCategoryToColor } from '../../utils/colors'

const map_original_size = [3180, 1950]

function getAisleCoords(WIDTH_CANVAS, HEIGHT_CANVAS) {
    const coordinates = []
    Object.keys(aisles).forEach(aislesKey => {
        const px_init = aisles[aislesKey].px_init;
        const px_end = aisles[aislesKey].px_end;

        const x_init = 1 / 150 * (px_init[0] - map_original_size[0] / 2) * map_original_size[0] / WIDTH_CANVAS
        const x_end = 1 / 150 * (px_end[0] - map_original_size[0] / 2) * map_original_size[0] / WIDTH_CANVAS
        const z_init = 1 / 150 * (px_init[1] - map_original_size[1] / 2) * map_original_size[1] / HEIGHT_CANVAS
        const z_end = 1 / 150 * (px_end[1] - map_original_size[1] / 2) * map_original_size[1] / HEIGHT_CANVAS

        let aisle_width = x_end - x_init;
        let aisle_height = z_end - z_init;

        const correct_len = 1.3
        aisle_width = aisle_width > correct_len ? aisle_width : correct_len
        aisle_height = aisle_height > correct_len ? aisle_height : correct_len

        const x_new = x_init + aisle_width / 2;
        const z_new = z_init + aisle_height / 2;

        coordinates.push({
            aisleName: aislesKey,
            color: "#64748b",
            // color: "#007455",
            position: [x_new, 0, z_new],
            // position: [x_new, 0, z_new],
            rotation: [0, 0, 0],
            boxSizes: [Math.abs(aisle_width), 0.35, Math.abs(aisle_height)],
        })
    });
    return coordinates;
}


function getAisleBayCoords(WIDTH_CANVAS, HEIGHT_CANVAS) {
    console.log(`%c WIDTH_CANVAS: ${WIDTH_CANVAS}, HEIGHT_CANVAS: ${HEIGHT_CANVAS}`, 'background: #222; color: #ba5');
    const coordinates = []
    bayAisles.forEach(bayAisle => {
        const px_init = bayAisle.px_init;
        const px_end = bayAisle.px_end;

        const x_init = px_init[0] * (WIDTH_CANVAS / map_original_size[0])
        const x_end = px_end[0] * (WIDTH_CANVAS / map_original_size[0])
        const z_init = px_init[1] * (HEIGHT_CANVAS / map_original_size[1])
        const z_end = px_end[1] * (HEIGHT_CANVAS / map_original_size[1])

        let aisle_width = x_end - x_init
        let aisle_height = z_end - z_init

        // const correct_len = 0
        // const correct_len = 1.3
        // aisle_width = aisle_width > correct_len ? aisle_width : correct_len
        // aisle_height = aisle_height > correct_len ? aisle_height : correct_len
        aisle_width = Math.abs(aisle_width)
        aisle_height = Math.abs(aisle_height)

        let x_new = x_init + aisle_width / 2
        let z_new = z_init + aisle_height / 2

        // console.log(bayAisle.aisle, ".-\n aisle_width:", aisle_width, "\n aisle_height:", aisle_height);
        // console.log(bayAisle.aisle,
        //     ".-\nx new:", x_new, 'old x: ', x_init,
        //     "\nz new:", z_new, 'old z: ', z_init);

        coordinates.push({
            aisleName: bayAisle.aisle,
            color: transformCategoryToColor(bayAisle[`category_${bayAisle.main_category}`]),
            position: [x_new, 0, z_new],
            rotation: [0, 0, 0],
            boxSizes: [aisle_width, 0.35, aisle_height],
        })
    });
    return coordinates;
}

function getHDCoords(WIDTH_CANVAS, HEIGHT_CANVAS) {
    const map_original_size = HD0121_points.map_size;
    console.log(`%c WIDTH_CANVAS: ${WIDTH_CANVAS}, HEIGHT_CANVAS: ${HEIGHT_CANVAS}`, 'background: #222; color: #008202');
    console.log(`%c map_original_size: ${map_original_size}`, 'background: #222; color: #008202');

    const coordinates = []
    const aisleCoordinates = {}
    const aisleLandmarks = {}
    HD0121_points.data?.forEach(({ coords, type, aisle, landmarks }) => {
        if (type === 'internal') {
            const new_coords = coords.map((coord) => {
                const x = coord.x;
                const y = coord.y;
                const x_new = x * (WIDTH_CANVAS / map_original_size[0])
                const y_new = y * (HEIGHT_CANVAS / map_original_size[1])

                return { x: x_new, y: y_new, aisle }
            })
            const newLandmarks = landmarks?.map((landmark) => {
                const [x_landmark_min, y_landmark_min] = landmark.map_point_min;
                const [x_landmark_max, y_landmark_max] = landmark.map_point_max;
                const x_landmark_min_new = x_landmark_min * (WIDTH_CANVAS / map_original_size[0])
                const x_landmark_max_new = x_landmark_max * (WIDTH_CANVAS / map_original_size[0])
                const y_landmark_min_new = y_landmark_min * (HEIGHT_CANVAS / map_original_size[1])
                const y_landmark_max_new = y_landmark_max * (HEIGHT_CANVAS / map_original_size[1])

                return { map_point_min: [x_landmark_min_new, y_landmark_min_new], map_point_max: [x_landmark_max_new, y_landmark_max_new], landmarkName: landmark.landmark }
            })

            if (aisle) {

                const aisleCode = aisle.split('-')[0]
                const rectangleCenter = new_coords.reduce((acc, curr) => {
                    acc.x_min = Math.min(curr.x, acc.x_min)
                    acc.x_max = Math.max(curr.x, acc.x_max)
                    acc.y_min = Math.min(curr.y, acc.y_min)
                    acc.y_max = Math.max(curr.y, acc.y_max)
                    return acc
                }, { x_min: Infinity, x_max: -Infinity, y_min: Infinity, y_max: -Infinity })

                if (!aisleCoordinates[aisleCode]) {
                    const x_max = rectangleCenter.x_max
                    const x_min = rectangleCenter.x_min
                    const y_max = rectangleCenter.y_max
                    const y_min = rectangleCenter.y_min

                    const center = { x: (x_max + x_min) / 2, y: (y_max + y_min) / 2 }
                    const width = Math.abs(x_max - x_min)
                    const height = Math.abs(y_max - y_min)
                    const isVertical = width < height

                    aisleCoordinates[aisleCode] = { ...rectangleCenter, center, width, height, isVertical }
                } else {
                    const x_min_curr = rectangleCenter.x_min
                    const x_max_curr = rectangleCenter.x_max
                    const y_min_curr = rectangleCenter.y_min
                    const y_max_curr = rectangleCenter.y_max

                    const x_min_bef = aisleCoordinates[aisleCode].x_min
                    const x_max_bef = aisleCoordinates[aisleCode].x_max
                    const y_min_bef = aisleCoordinates[aisleCode].y_min
                    const y_max_bef = aisleCoordinates[aisleCode].y_max

                    const x_min = Math.min(x_max_curr, x_max_bef) // right side
                    const x_max = Math.max(x_min_curr, x_min_bef) // left side
                    const y_min = Math.min(y_max_curr, y_max_bef) // top side
                    const y_max = Math.max(y_min_curr, y_min_bef) // bottom side

                    const center = { x: (x_min + x_max) / 2, y: (y_min + y_max) / 2 }
                    const width = Math.abs(x_max - x_min)
                    const height = Math.abs(y_max - y_min)
                    const isVertical = width < height

                    aisleCoordinates[aisleCode] = { center, width, height, isVertical }
                }
            }

            aisleLandmarks[aisle] = newLandmarks
            coordinates.push(new_coords)
        }
    });

    console.log('coordinates: ', coordinates, 'aisleCoordinates: ', aisleCoordinates, 'aisleLandmarks: ', aisleLandmarks);
    return [coordinates, aisleCoordinates, aisleLandmarks];
}

function getHDAisles(WIDTH_CANVAS, HEIGHT_CANVAS) {
    const map_original_size = HD0121_points.map_size;
    console.log(`%c WIDTH_CANVAS: ${WIDTH_CANVAS}, HEIGHT_CANVAS: ${HEIGHT_CANVAS}`, 'background: #222; color: #008202');
    console.log(`%c map_original_size: ${map_original_size}`, 'background: #222; color: #008202');

    const coordinates = []
    Object.entries(HD0121_points.cartel_aisles_info).forEach(([aisle, coord]) => {

        const [px_init, py_end] = coord.px_init
        const [px_end, py_init] = coord.px_end
        const [x_center, y_center] = coord.center

        const [px_init_new, py_end_new] = [px_init * (WIDTH_CANVAS / map_original_size[0]), py_end * (HEIGHT_CANVAS / map_original_size[1])]
        const [px_end_new, py_init_new] = [px_end * (WIDTH_CANVAS / map_original_size[0]), py_init * (HEIGHT_CANVAS / map_original_size[1])]
        const [x_center_new, y_center_new] = [x_center * (WIDTH_CANVAS / map_original_size[0]), y_center * (HEIGHT_CANVAS / map_original_size[1])]

        coordinates.push({ 'px_init': [px_init_new, py_end_new], 'px_end': [px_end_new, py_init_new], 'center': [x_center_new, y_center_new], aisle })
    });

    console.log('Aisle coordinates: ', coordinates);
    return coordinates;
}

function centerPolygon(flatVertices, height) {
    // Calcular el centro actual de la figura plana
    const center = calculateCenter(flatVertices, height);

    // Calcular el desplazamiento necesario para centrar en el origen
    const offsetX = -center.x;
    const offsetZ = -center.z;

    // Aplicar el desplazamiento a todos los vértices
    const centeredVertices = flatVertices.map((vertex) => ({
        x: vertex.x + offsetX,
        // y: -height,
        y: 0,
        z: vertex.z + offsetZ,
    }));

    return centeredVertices;
}

function flatPolygonTo3DGeometry(flatVertices) {
    const HEIGHT = 0.05;
    const center = calculateCenter(flatVertices, HEIGHT);
    flatVertices = centerPolygon(flatVertices, HEIGHT);
    const vertices = [];
    const indices = [];

    const radius_x = Math.abs(flatVertices[1].x - flatVertices[0].x);
    const radius_z = Math.abs(flatVertices[1].z - flatVertices[2].z);
    const radius_y = HEIGHT;

    // Número de lados del polígono plano
    const sides = flatVertices.length;

    // Calcular el radio dinámicamente
    const radius = 0.5 / Math.tan(Math.PI / sides);

    // Replicar los vértices de la figura plana solo en la base (plana) y elevarlos

    for (const vertex of flatVertices) {
        vertices.push(
            vertex.x, vertex.y, vertex.z,     // Vértice en la base (plana)
        );
    }
    for (const vertex of flatVertices) {
        vertices.push(
            vertex.x, HEIGHT, vertex.z    // Vértice en la parte superior
        );
    }


    // Definir los índices para las caras del polígono en el cubo
    for (let i = 0; i < sides; i++) {
        const base = i;
        const next = (i + 1) % sides;

        // Caras laterales
        indices.push(base, next, base + sides);
        indices.push(next, next + sides, base + sides);

        // Conectar la parte superior con la base del vértice siguiente
        indices.push(next, base + sides, next + sides);
    }

    return { vertices, indices, radius, center, radius_x, radius_y, radius_z };
}

function calculateCenter(flatVertices, HEIGHT) {
    // Calcular el centro de la figura plana
    const center = { x: 0, z: 0 };
    for (const vertex of flatVertices) {
        center.x += vertex.x;
        center.z += vertex.z;
    }
    center.x /= flatVertices.length;
    center.y = HEIGHT;
    center.z /= flatVertices.length;
    return center;
}

function simplifyToParallelogram(polygonVertices) {
    if (polygonVertices.length < 4) {
        // No se puede simplificar a un paralelogramo si hay menos de 4 vértices.
        return polygonVertices;
    }

    // Encontrar los puntos medios de los lados opuestos del polígono
    const midpoints = [];
    const numVertices = polygonVertices.length;

    for (let i = 0; i < numVertices; i++) {
        const currentVertex = polygonVertices[i];
        const nextVertex = polygonVertices[(i + 1) % numVertices];

        const midpointX = (currentVertex.x + nextVertex.x) / 2;
        const midpointY = (currentVertex.y + nextVertex.y) / 2;
        const midpointZ = (currentVertex.z + nextVertex.z) / 2;

        midpoints.push({ x: midpointX, y: midpointY, z: midpointZ });
    }

    // Crear un nuevo arreglo de vértices con los puntos medios
    const parallelogramVertices = [];

    for (let i = 0; i < numVertices; i++) {
        const currentVertex = polygonVertices[i];
        const midpoint = midpoints[i];

        parallelogramVertices.push(currentVertex);
        parallelogramVertices.push(midpoint);
    }

    return parallelogramVertices;
}

function simplifyToRectangle(polygonVertices) {
    if (polygonVertices.length < 4) {
        // No se puede simplificar a un rectángulo si hay menos de 4 vértices.
        return polygonVertices;
    }

    // Encontrar los vértices extremos del polígono
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (const vertex of polygonVertices) {
        if (vertex.x < minX) minX = vertex.x;
        if (vertex.y < minY) minY = vertex.y;
        if (vertex.x > maxX) maxX = vertex.x;
        if (vertex.y > maxY) maxY = vertex.y;
    }

    // Crear un nuevo arreglo de vértices para formar un rectángulo
    const rectangleVertices = [
        { x: minX, y: minY, z: 0 },
        { x: maxX, y: minY, z: 0 },
        { x: maxX, y: maxY, z: 0 },
        { x: minX, y: maxY, z: 0 },
    ];

    return rectangleVertices;
}



export {
    getAisleCoords,
    getAisleBayCoords,
    flatPolygonTo3DGeometry,
    calculateCenter,
    getHDCoords,
    simplifyToParallelogram,
    simplifyToRectangle,
    getHDAisles,
}
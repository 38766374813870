import { connect } from 'react-redux';
import DigitalShelf from '../../../../../src/app/components/geometry/ShelfModal/digitalShelf/DigitalShelf';

import {
	getWallsAisles,
	getWallImage,
	getLandmarks,
	getAllLandmark,
	getCoords,
	setWallLandmarks,
	setSelectedStore,
	setAisleState,
	setLandmarkState,
	setDateState,
	setProductState,
	getAislePromotions,
} from 'core/slices/wall';

import {
	getWallsAislesData,
	getWallLandmarks,
	getAllLandmarks,
	getCoordsInfo,
	
	isLoadingAislesData,
	isLoadingWallLandmarks,
	isLoadingLandmark,
	isLoadingCoordsInfo,
	
	getSelectedWallStore,
	getSelectedWallAisle,
	getSelectedWallLandmark,
	getSelectedWallDate,
	getSelectedWallProduct,
	getAisleLandmarkState,
} from 'core/selectors';

export default connect(state => ({
	aislesData: getWallsAislesData(state),
	wallLandmarks: getWallLandmarks(state),
	coordsInfo: getCoordsInfo(state),
	selectedStore: getSelectedWallStore(state),
	selectedAisle: getSelectedWallAisle(state),
	selectedLandmark: getSelectedWallLandmark(state),
	dateState: getSelectedWallDate(state),
	selectedProduct: getSelectedWallProduct(state),
	aisleLandmarks: getAisleLandmarkState(state),
	
	isLoadingAislesData: isLoadingAislesData(state),
	isLoadingLandmarks: isLoadingWallLandmarks(state),
	landmarkData: getAllLandmarks(state),
	isLoadingLandmark: isLoadingLandmark(state),
	isLoadingCoordsInfo: isLoadingCoordsInfo(state)
}), {
	getWallsAisles,
	getWallImage,
	getLandmarks,
	getAllLandmark,
	getCoords,
	setWallLandmarks,
	setSelectedStore,
	setAisleState,
	setLandmarkState,
	setDateState,
	setProductState,
	getAislePromotions
})(DigitalShelf);

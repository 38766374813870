import { useRef, useEffect, Suspense, Fragment, useState } from "react";
import * as THREE from "three";
import * as ThreeMeshUI from "three-mesh-ui";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { flatPolygonTo3DGeometry, getAisleBayCoords, getHDAisles, getHDCoords, simplifyToParallelogram, simplifyToRectangle } from '../home/map';
import Box from '../home/Boxes';
import TextCanvas from '../home/text';
import RobotoFont from '../../../assets/fonts/Roboto-Medium-msdf.json';
import RobotoFontTexture from '../../../assets/fonts/Roboto-Medium.png';
import BackgroundImage from '../../../assets/images/blackbg.jpeg';
import { getRandomColor } from '../../utils/colors';
import ShelfModal from "./ShelfModal/ShelfModal";
import { Button } from '@mui/material';
// import BackgroundImage from '../../../assets/images/whitebg.jpeg';

const HEIGHT_CAMERA = 4;
const Geometry = () => {
    const mountRef = useRef(null);
    const [intersected, setIntersected] = useState(null);
    const [MousePosition, setMousePosition] = useState({
        left: 0,
        top: 0
    })
    const [cubes, setCubes] = useState(null);
    const [cameraRef, setCamera] = useState(null);
    const [orbitControls, setOrbitControls] = useState(null);
    const [canParseLandmark, setCanParseLandmark] = useState(true);
    const [resizeRef, setResizeRef] = useState(null);
    const [rendererRef, setRendererRef] = useState(null);

    const [open, setOpen] = useState(false);
    const [modalIntersected, setModalIntersected] = useState(false);
    const [colors, setColors] = useState({
        color: '#000000',
        backgroundColor: '#FFFFFF',
        aisleColor: 0xF96302,
        landmarkColor: 0x0ea5e9,
        AisleTextColor: 0xFFFFFF,
        AisleOpacity: 0.6,
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        console.log("AAA");
        //Data from the div as canvas
        const currentRef = mountRef.current;
        const { clientWidth: width, clientHeight: height } = currentRef;

        //Scene, Camera and Renderer
        const pos = { x: 20, y: HEIGHT_CAMERA, z: 2 }

        const scene = new THREE.Scene();
        console.log("position camera: ", cameraRef?.position);
        const camera = new THREE.PerspectiveCamera(55, width / height, 0.1, 100);

        // const targetpos = [13, 8, 3]
        // const targetpos = [9, 7, 3]
        // const targetpos = [18, 4, 3]
        // const targetpos = intersected?.position ? [intersected.position.x, HEIGHT_CAMERA, intersected.position.z] : [10, HEIGHT_CAMERA, 3];
        // const orbitPos = intersected?.position ? [intersected.position.x, 0, intersected.position.z] : [10, 0, 3];
        const targetpos = [10, HEIGHT_CAMERA, 3];
        const orbitPos = [10, 0, 3];
        const orbitPosition = new THREE.Vector3(...orbitPos);
        const targetPosition = new THREE.Vector3(...targetpos);
        // const offset = new THREE.Vector3(1, 2, 1);
        const offset = new THREE.Vector3(0, 1, 0);
        const cameraPosition = targetPosition.clone().add(offset);
        camera.position.copy(cameraPosition);
        camera.lookAt(targetPosition);
        camera.updateProjectionMatrix();
        scene.add(camera);

        // ** Create lights **
        let light = new THREE.DirectionalLight(0xEEEEEE, 1);
        light.position.set(10, 20, 10);
        scene.add(light);
        let lightAmb = new THREE.AmbientLight(0xEEEEEE, 2.4);
        lightAmb.position.set(pos.x, pos.y, pos.z);
        scene.add(lightAmb);
        // ** Lights **
        // ** Texture Loader **
        // const loader = new THREE.TextureLoader();
        // loader.load(BackgroundImage, function (texture) {
        // scene.background = texture;
        // });
        // ** Texture Loader **

        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.autoClear = false;
        renderer.setClearColor(0x000000, 0.0);
        renderer.setSize(width, height);
        currentRef.appendChild(renderer.domElement);
        setRendererRef(renderer);

        //Orbit Controls
        const orbitControls = new OrbitControls(camera, renderer.domElement);
        // orbitControls.enableDamping = true;
        orbitControls.zoomSpeed = 1;
        // orbitControls.enableRotate = false;
        orbitControls.target.copy(orbitPosition);
        setOrbitControls(orbitControls);

        // orbitControls.addEventListener("change", event => {
        //     console.log(orbitControls.object.position);
        // });

        //Axes helper
        // const axesHelper = new THREE.AxesHelper(2);
        // scene.add(axesHelper);

        //Grid Helper
        const size = 20;
        const divisions = size;
        // const gridHelper = new THREE.GridHelper(size, divisions);
        // scene.add(gridHelper);

        const parseArrayCoords = (coords) => coords.map((coord) => {
            return { ...coord, y: coord.z, z: coord.y }
        });


        let cubes = new THREE.Object3D();
        scene.add(cubes);
        const newWidth = size / 1.7;
        const newHeight = size / 1.7;
        const [homeDepotAisles, homeDepotCartelAisle, aisleLandmarks] = getHDCoords(newWidth, newHeight)
        const addCubes = (cubes, aisles, canParseLandmark = false) => {
            homeDepotAisles.forEach((coords) => {
                const aisle = coords[0].aisle;
                const landmarks = aisleLandmarks[aisle];
                // coords = simplifyToParallelogram(coords);
                coords = simplifyToRectangle(coords);

                const { vertices, indices, radius, center, radius_x, radius_y, radius_z } = flatPolygonTo3DGeometry(parseArrayCoords(coords));
                // const verticesOfCube = vertices;
                // const indicesOfFaces = indices;
                // Polygon
                // const geometry = new THREE.PolyhedronGeometry(verticesOfCube, indicesOfFaces, 1, 0);
                // const material = new THREE.MeshBasicMaterial({
                //     // color: 0x00ff00, random color
                //     color: Math.random() * 0xffffff,
                //     wireframe: true
                // });
                // const cube = new THREE.Mesh(geometry, material);
                // scene.add(cube);
                // cube.position.set(center.x, center.y, center.z);

                // Box way
                const landmark_len = landmarks?.length;
                if (landmark_len && canParseLandmark) {
                    landmarks.forEach((landmark, landmarkIndex) => {
                        const [newCenter, boxSizes] = handleLandmarksCoordsEquidistant(landmarkIndex, landmark_len, center, radius_x, radius_y, radius_z);
                        const boxGeometry = new THREE.BoxGeometry(...boxSizes);
                        const randomColor = getRandomColor(0)
                        // const boxMaterial = new THREE.MeshPhongMaterial({
                        const boxMaterial = new THREE.MeshStandardMaterial({
                            // const boxMaterial = new THREE.MeshNormalMaterial({
                            // const boxMaterial = new THREE.MeshBasicMaterial({
                            color: randomColor,
                            // wireframe: true,
                        });

                        // const cube = new THREE.Mesh(boxGeometry, boxMaterial);

                        // 0xeeefee
                        // 0xd9dad7
                        // 0xcecece
                        // 0xbfbfbf
                        const cube = createCubeWithBorder(boxGeometry, randomColor, 0xbfbfbf, 0.001);
                        // scene.add(cube);
                        cube.position.set(newCenter.x, 0, newCenter.z);
                        // set useful data
                        cube.userData = { aisle, landmark: landmark, coords: [newCenter.x, 0, newCenter.z] };
                        cubes.add(cube);
                    });
                } else {
                    const [newCenter, boxSizes] = handleLandmarksCoordsEquidistant(null, 1, center, radius_x, radius_y, radius_z);
                    const boxGeometry = new THREE.BoxGeometry(...boxSizes);
                    const randomColor = getRandomColor(0)
                    const boxMaterial = new THREE.MeshStandardMaterial({
                        // const boxMaterial = new THREE.MeshNormalMaterial({
                        // const boxMaterial = new THREE.MeshBasicMaterial({
                        color: randomColor,
                        // wireframe: true,
                    });

                    const cube = new THREE.Mesh(boxGeometry, boxMaterial);
                    // scene.add(cube);
                    cube.position.set(newCenter.x, 0, newCenter.z);
                    // set useful data
                    cube.userData = { aisle, coords: [newCenter.x, 0, newCenter.z] };
                    cubes.add(cube);
                }

                // TEXT
                const isDisabledText = true;
                if (!isDisabledText && aisle) {
                    const container = new ThreeMeshUI.Block({
                        width: 1,
                        height: 1,
                        backgroundColor: new THREE.Color("#FFF"),
                        padding: 0.2,
                        fontFamily: RobotoFont,
                        fontTexture: RobotoFontTexture,
                    });
                    const randomN = Math.random() * 10;
                    // const cartel_pos = [center.x, randomN < 5 ? 0.5 : 2, center.z + 0.05];
                    const cartel_pos = [center.x, 0, center.z];
                    container.position.set(...cartel_pos);
                    // container.rotation.x = 0;
                    // container.rotation.x = randomN < 5 ? 0 : -0.55;
                    // container.rotation.y = 0;
                    // container.rotation.x = -Math.PI / 2;
                    // container.rotation.y = randomN < 5 ? 1.5 : 0;
                    scene.add(container);
                    const text = new ThreeMeshUI.Text({ content: aisle });

                    // const text = new ThreeMeshUI.Text({ content: 'none' });
                    container.add(text);
                    text.set({
                        fontColor: new THREE.Color("#000"),
                        fontSize: 0.2
                    });
                    // container.set({
                    //     textAlign: 'right',
                    //     justifyContent: 'end', // could be 'start', 'center', 'end', 'space-between', 'space-around', 'space-evenly'  
                    //     padding: 0.03
                    // });
                }
            });
            return cubes;
        }
        addCubes(cubes, homeDepotAisles, true);

        // const homeDepotCartelAisle = getHDAisles(newWidth, newHeight)
        Object.entries(homeDepotCartelAisle).forEach(([aisle, { center, height, width, isVertical }]) => {
            // Text
            const isDisabledText = false;
            if (!isDisabledText && aisle) {
                const container = new ThreeMeshUI.Block({
                    width: 0.2,
                    height: 0.2,
                    backgroundOpacity: colors.AisleOpacity,
                    // backgroundOpacity: 0,
                    borderRadius: 0.05,
                    // backgroundColor: new THREE.Color("#FFFFFFFF"),
                    // backgroundColor: new THREE.Color("#404040"),
                    backgroundColor: new THREE.Color(colors.aisleColor),
                    // backgroundColor: new THREE.Color("#FFBB42"),
                    // backgroundColor: new THREE.Color("#F96302"),
                    // backgroundColor: new THREE.Color("#0ea5e9"),
                    // backgroundColor: new THREE.Color("#F00"),
                    padding: 0,
                    fontFamily: RobotoFont,
                    fontTexture: RobotoFontTexture,
                });
                const randomN = Math.random() * 10;
                // const cartel_pos = [center.x, randomN < 5 ? 0.5 : 2, center.z + 0.05];

                const cartel_pos = [
                    isVertical ? center.x : center.x + width / 2,
                    0.1,
                    isVertical ? center.y + height / 2 : center.y
                ];
                // const cartel_pos = [px_init[0], 0, px_init[1]];
                // const cartel_pos = [px_end[0], 0, px_end[1]];
                container.position.set(...cartel_pos);
                // container.rotation.x = 0;
                // container.rotation.x = randomN < 5 ? 0 : -0.55;
                container.rotation.y = 0;
                container.rotation.x = -Math.PI / 2;
                // container.rotation.y = randomN < 5 ? 1.5 : 0;
                scene.add(container);
                const text = new ThreeMeshUI.Text({ content: aisle });

                // const text = new ThreeMeshUI.Text({ content: 'none' });
                container.add(text);
                text.set({
                    // fontColor: new THREE.Color("#FFF"),
                    fontColor: new THREE.Color(colors.AisleTextColor),
                    // fontColor: new THREE.Color("#000"),
                    // fontColor: new THREE.Color("#F00"),
                    fontSize: 0.1
                });
                container.set({
                    textAlign: 'center',
                    justifyContent: 'center', // could be 'start', 'center', 'end', 'space-between', 'space-around', 'space-evenly'
                    padding: 0
                });
            }
        });


        //Resize the canvas
        const resize = () => {
            renderer.setSize(currentRef.clientWidth, currentRef.clientHeight);
            camera.aspect = currentRef.clientWidth / currentRef.clientHeight;
            camera.updateProjectionMatrix();
        };
        setResizeRef(resize);

        let canParseLandmark = true;
        const wheel = () => {
            let newCubes;
            const currentZoom = camera?.position.y;
            const ZOOM_TRESHOLD = 5;
            if (currentZoom > ZOOM_TRESHOLD && canParseLandmark) {
                scene.remove(cubes);
                newCubes = new THREE.Object3D();
                cubes = newCubes;
                scene.add(cubes);
                addCubes(newCubes, homeDepotAisles, false);
                canParseLandmark = false
                setCubes(cubes);
            } else if (currentZoom < ZOOM_TRESHOLD && !canParseLandmark) {
                scene.remove(cubes);
                newCubes = new THREE.Object3D();
                cubes = newCubes;
                scene.add(cubes);
                addCubes(cubes, homeDepotAisles, true);
                canParseLandmark = true
                setCubes(cubes);
            }

        }
        // const onMouseMove = (event, intersected, setIntersected) => {
        //     const rect = currentRef.getBoundingClientRect();
        //     mouseVector.x = ((event.clientX - rect.left) / currentRef.clientWidth) * 2 - 1;
        //     mouseVector.y = -((event.clientY - rect.top) / currentRef.clientHeight) * 2 + 1;

        //     raycaster.setFromCamera(mouseVector.clone(), camera);
        //     let intersects = raycaster.intersectObjects(cubes.children, false);
        //     // handleIntersect(intersects);

        //     if (intersects.length > 0) {
        //         const newIntersected = intersects[0].object;
        //         if (intersected !== newIntersected) {
        //             console.log('intersects: ', intersects);
        //             console.log('intersected: ', intersected);
        //             if (intersected) intersected.material.color.setHex(intersected.currentHex);
        //             newIntersected.currentHex = newIntersected.material.color.getHex();
        //             newIntersected.material.color.setHex(0x4200FF);
        //             setIntersected(newIntersected);
        //         }
        //     } else {
        //         if (intersected) intersected.material.color.setHex(intersected.currentHex);
        //         setIntersected(null);
        //     }
        //     // cubes.children.forEach(function (cube) {
        //     //     cube.material.color.setRGB(cube.grayness, cube.grayness, cube.grayness);
        //     // });

        //     // if (intersects.length > 0) {
        //     //     if (intersected !== intersects[0].object) {
        //     //         console.log("intersected: ", intersected);
        //     //         if (intersected) intersected.material.color.setHex(intersected.currentHex);
        //     //         const newIntersected = intersects[0].object;
        //     //         newIntersected.currentHex = newIntersected.material.color.getHex();
        //     //         newIntersected.material.color.setHex(0x4200FF);
        //     //         handleIntersected(newIntersected)
        //     //     }
        //     // } else {
        //     //     if (intersected) intersected.material.color.setHex(intersected.currentHex);
        //     //     setIntersected(null);
        //     // }

        //     // for (var i = 0; i < intersects.length; i++) {
        //     //     var intersection = intersects[i],
        //     //         obj = intersection.object;

        //     //     obj.material.color.setRGB(1.0 - i / intersects.length, 0, 0);
        //     // }
        // }

        // User interaction
        window.addEventListener("resize", resize, false);
        window.addEventListener('wheel', wheel);
        // window.addEventListener('mousemove', onMouseMove, false);

        //Animate the scene and for update the orbit controls
        const animateScene = () => {
            ThreeMeshUI.update();
            orbitControls.update();
            requestAnimationFrame(animateScene);
            renderer.render(scene, camera);
        };

        setCubes(cubes);
        setCamera(camera);
        animateScene();

        //Clean up
        return () => {
            try {
                window.removeEventListener('wheel', wheel);
                window.removeEventListener("resize", resize);
                currentRef.removeChild(renderer.domElement);
            } catch (error) {
                console.log("error: ", error);
            }
        };
    }, [colors]);

    // Function to create a cube with black edge lines
    const createCubeWithBorder = (boxGeometry, mainColor, edgeColor, edgeSeparation) => {
        const mainMaterial = new THREE.MeshStandardMaterial({ color: mainColor }); // Main material for the cube

        const cube = new THREE.Mesh(boxGeometry, mainMaterial); // Main cube

        // Create edges geometry and material
        const edgesGeometry = new THREE.EdgesGeometry(boxGeometry);
        const edgesMaterial = new THREE.LineBasicMaterial({ color: edgeColor }); // Edge color

        // Create edges mesh
        const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);

        // Apply a small offset to the edges to separate them from the cube
        edges.position.set(edgeSeparation, edgeSeparation, edgeSeparation);

        cube.add(edges); // Add edges as a child of the main cube

        return cube; // Return the main cube with separated edges
    };

    const handleLandmarksCoordsEquidistant = (landmarkIndex, totalLandmarks, center, radius_x, radius_y, radius_z) => {
        let newCenter = center;
        let boxSizes = [
            radius_x,
            radius_y,
            radius_z
        ];
        if (landmarkIndex !== null) {
            const isVertical = radius_x < radius_z;
            const [x_landmark_width, z_landmark_height] = [radius_x / totalLandmarks, radius_z / totalLandmarks];
            const [center_box_x, center_box_z] = [center.x, center.z];
            const [x_landmark_center, z_landmark_center] = [
                center_box_x - radius_x / 2 + (2 * landmarkIndex + 1) * radius_x / (2 * totalLandmarks),
                center_box_z - radius_z / 2 + (2 * landmarkIndex + 1) * radius_z / (2 * totalLandmarks)]

            newCenter = {
                x: isVertical ? center_box_x : x_landmark_center,
                y: radius_y,
                z: isVertical ? z_landmark_center : center_box_z
            }
            boxSizes = [
                isVertical ? radius_x : x_landmark_width,
                radius_y,
                isVertical ? z_landmark_height : radius_z,
            ];
        }
        return [newCenter, boxSizes]
    }

    const handleLandmarksCoords = (landmark, center, radius_x, radius_y, radius_z) => {
        let newCenter = center;
        let boxSizes = [
            radius_x,
            radius_y,
            radius_z
        ];
        if (landmark) {
            const isVertical = radius_x < radius_z;
            const [x_landmark_min, z_landmark_min] = landmark.map_point_min;
            const [x_landmark_max, z_landmark_max] = landmark.map_point_max;
            const [x_landmark_center, z_landmark_center] = [(x_landmark_min + x_landmark_max) / 2, (z_landmark_min + z_landmark_max) / 2];
            const [x_landmark_width, z_landmark_height] = [x_landmark_max - x_landmark_min, z_landmark_max - z_landmark_min];
            const [center_box_x, center_box_z] = [center.x, center.z];

            newCenter = {
                x: isVertical ? center_box_x : center_box_x + (x_landmark_center - center_box_x),
                y: radius_y,
                z: isVertical ? center_box_z + (z_landmark_center - center_box_z) : center_box_z
            }
            boxSizes = [
                isVertical ? radius_x : x_landmark_width,
                radius_y,
                isVertical ? z_landmark_height : radius_z,
            ];
        }
        return [newCenter, boxSizes]
    }

    const handleIntersect = (intersects) => {
        if (intersects.length > 0) {
            const newIntersected = intersects[0].object;
            if (JSON.stringify(intersected) !== JSON.stringify(newIntersected)) {
                if (intersected && !open) {
                    intersected.material.color.setHex(intersected.currentHex);
                    intersected.userData.isSelected = false;
                }
                newIntersected.currentHex = newIntersected.material.color.getHex();
                newIntersected.material.color.setHex(0xF96302);
                setIntersected(newIntersected);
            }
        } else {
            if (!intersected?.userData?.isSelected) {
                if (intersected) {
                    intersected.material.color.setHex(intersected.currentHex);
                }
                setIntersected(null);
            }
        }
    }

    useEffect(() => {
        if (MousePosition && cameraRef && cubes) {
            const raycaster = new THREE.Raycaster();
            const mouseVector = new THREE.Vector2();

            // console.log('mousePosition: ', MousePosition);
            const currentRef = mountRef.current;

            const rect = currentRef.getBoundingClientRect();
            mouseVector.x = ((MousePosition.clientX - rect.left) / currentRef.clientWidth) * 2 - 1;
            mouseVector.y = -((MousePosition.clientY - rect.top) / currentRef.clientHeight) * 2 + 1;

            raycaster.setFromCamera(mouseVector.clone(), cameraRef);
            let intersects = raycaster.intersectObjects(cubes.children, false);
            handleIntersect(intersects);
        }

    }, [MousePosition]);

    const handleMouseMove = (e) => { setMousePosition({ clientX: e.clientX, clientY: e.clientY }); }


    const centerCameraOnObjectX = (object) => {
        const targetPosition = object.position.clone();
        const distance = 3; // Distancia desde la cámara al objeto
        const offset = new THREE.Vector3(0, 5, 0); // Modificar solo la coordenada X
        const cameraPosition = targetPosition.clone().add(offset);

        cameraRef.position.copy(cameraPosition);
        cameraRef.lookAt(targetPosition);
        cameraRef.updateProjectionMatrix();
        orbitControls.target.copy(targetPosition); // Actualiza el punto de enfoque de OrbitControls
    };

    const moveCameraToPosition = (object, duration = 1000) => {
        let newTargetPosition = object.position.clone();
        const currentCameraPosition = cameraRef.position.clone();
        const newCameraPosition = new THREE.Vector3(newTargetPosition.x, HEIGHT_CAMERA + 1, newTargetPosition.z - 1);
        const startTime = Date.now();
        const endTime = startTime + duration;

        const animateCamera = () => {
            const now = Date.now();
            const elapsed = Math.min(now - startTime, duration);
            const t = elapsed / duration;

            let newPosition = currentCameraPosition.clone().lerp(newCameraPosition, t);
            newPosition = new THREE.Vector3(newPosition.x, newPosition.y, newPosition.z);
            const newTargetPosition = new THREE.Vector3(newPosition.x, 0, newPosition.z);
            cameraRef.position.copy(newPosition);
            cameraRef.lookAt(newTargetPosition);
            cameraRef.updateProjectionMatrix();
            orbitControls.target.copy(newTargetPosition);

            if (now < endTime) {
                requestAnimationFrame(animateCamera);
            }
        };

        animateCamera();

        setTimeout(() => {
            // setCanParseLandmark(true);
            console.log(" Changin to landmark view ");
            const WheelEvent = new Event('wheel', {
                deltaY: 10,
                deltaMode: 100,
            });
            document.getElementById('contenedor3D').dispatchEvent(WheelEvent);
            // currentRef.dispatchEvent(WheelEvent);
        }, duration);
    };

    const handleClick = () => {
        if (intersected) {
            console.log('1. intersected: ', intersected.userData);
            console.log('2. intersected: ', intersected.position);
            if (intersected?.userData?.isSelected !== null) {
                intersected.userData.isSelected = !intersected?.userData?.isSelected;
            }
            // centerCameraOnObjectX(intersected);
            // centerCameraOnObject(intersected);
            const delay = 500;
            moveCameraToPosition(intersected, delay);
            setModalIntersected(intersected.userData);
            setTimeout(() => {
                handleClickOpen();
            }, delay - 20)
        }
    }
    const handleMouseWheel = () => {
        // console.log('camera: ', camera);
        // const currentZoom = cameraRef.position.y;
        // const ZOOM_TRESHOLD = 4;
        // if (currentZoom > ZOOM_TRESHOLD && canParseLandmark) {
        //     console.log(" Changin to aisle view ");
        //     // setCanParseLandmark(false)
        // } else if (currentZoom < ZOOM_TRESHOLD && !canParseLandmark) {
        //     console.log(" Changin to landmark view ");
        //     // setCanParseLandmark(true)
        // }

        // console.log(" current zoom: ", currentZoom);
    }
    const handleColors = () => {
        const grey = {
            color: '#000000',
            backgroundColor: '#FFFFFF',
            aisleColor: 0x404040,
            landmarkColor: 0x0ea5e9,
            AisleTextColor: 0x404040,
            AisleOpacity: 0.05,
        }
        const orange = {
            color: '#000000',
            backgroundColor: '#FFFFFF',
            aisleColor: 0xF96302,
            landmarkColor: 0x0ea5e9,
            AisleTextColor: 0xFFFFFF,
            AisleOpacity: 0.6,
        }
        setColors(
            JSON.stringify(colors) === JSON.stringify(grey) ? orange : grey
        )
    }


    return (
        <>
            <div
                id="contenedor3D"
                onWheel={handleMouseWheel}
                onMouseMove={handleMouseMove}
                onClick={(e) => handleClick()}
                className='Contenedor3D'
                ref={mountRef}
                style={{ width: "100%", height: "100vh" }}
            >
            </div>
            <ShelfModal open={open} handleClose={handleClose} modalIntersected={modalIntersected} />
            <Button variant="contained" onClick={() => { handleColors() }}>Toogle Aisle Colors</Button>
        </>
    );
};

export default Geometry;

import {
	Backdrop,
	Box,
	Card,
	CardContent,
	Chip,
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';


import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// My Components
import WallDrawer from "./WallDrawer";
import CustomCardContent from "./CustomCardContent";
import { getChainMeaning } from 'core/utils/parsers';
import { colorBasedOnTheme } from "../../../../utils/colors";
import React from "react";

// constants
const RADIUSPLUS = 19;

export default function Wall(props) {
	const { t } = useTranslation();
	const theme = useTheme();
	const {
		selectedAisle,
		selectedLandmark,
		selectedStore,
		selectedClient,
		dateState,
		wallImageData,
		landmarkInfo,
		isLoadingCoordsInfo,
		isLoadingWallImage,
		wallLandmarks,
		// isLoadingLandmarks,
		excelLandmarkList,
		isLoadingLandmarksExcel,
		getDigitalShelfExcel,
		selectedProductState,
		getExcelLandmarks,
		aislesData,
		clearCoordsState,
		clearWallImageData,
		aislePromotions,
		aisleLandmarks,
		misplacedSignedImages,
		loadingMisplacedSignedImages,
		getMisplacedProductSignedImages
	} = props;

	const wallImage = new Image();

	const colors = {
		stock: { color: '#349E15', focusedColor: '#ABF096', color2: '#143f07', text: '#fff' },
		stockout: { color: '#D80A3B', focusedColor: '#EA99AC', color2: '#60051b', text: '#fff' },
		spread_to_fill: { color: '#DB9105', focusedColor: '#FFD8AD', color2: '#7a6235', text: '#000' },
		price_difference: { color: '#FFD740', focusedColor: '#FFEDAB', color2: '#b79d00', text: '#000' },
		hua: { color: '#4065F6', focusedColor: '#91C8E4', color2: '#2E4890', text: '#fff' },
		promotion: { color: '#F6F4EB', focusedColor: '#fff', color2: '#ffdef0', text: '#000' },
		misplaced_product: { color: '#40F8FF', focusedColor: '#97FFF6', color2: '#DCFFFD', text: '#000' }
	};

	const [canvasHeight, setCanvasHeight] = useState(500);
	const [canvasWidth, setCanvasWidth] = useState(1000);
	const [originalWidth, setOriginalWidth] = useState(null);
	const [originalHeight, setOriginalHeight] = useState(null);
	const [zoom, setZoom] = useState(true);
	const [zoomedWallImageStyle, setZoomedWallImageStyle] = useState({ height: 140, width: 310, position: 'relative' });


	const [landmarksVisible, setLandmarksVisible] = useState(true);
	const [alertPills, setAlertPills] = useState({ stock: 0, stockout: 0, spread_to_fill: 0, price_difference: 0, promotion: 0, hua: 0, misplaced_product: 0 });
	const [categoryFilters, setCategoryFilters] = useState([]);

	const [selectedStatus, setSelectedStatus] = useState(new Set(['stock', 'stockout', 'spread_to_fill', 'price_difference', 'promotion', 'hua', 'misplaced_product']));
	const [selectedCategories, setSelectedCategories] = useState(new Set());

	const isHoverOn = useRef(null)

	const [selectedProduct, setSelectedProduct] = useState({});
	const [isTooltipFixed, setIsTooltipFixed] = useState(false);
	const selectedProductIndex = useRef(null)

	const [isLoadingExcel, setIsLoadingExcel] = useState(false);

	// general pill selection
	const handleSelectionChanged = (value, selectedValues, setSelected) => {
		const newSet = new Set(selectedValues);
		if (newSet.has(value))
			newSet.delete(value);
		else
			newSet.add(value);
		setSelected(newSet);
	}

	// load img data
	useEffect(() => {
		if (!isLoadingWallImage && Object.keys(wallImageData).length > 0) {
			wallImage.src = wallImageData.signed_url;
			wallImage.onload = () => {
				let canvas_element = document.getElementById('wallScroll');
				if(canvas_element) {
					let canvas_height = canvas_element.offsetHeight;
					let newImgWidth = canvas_height * (wallImage.width / wallImage.height);
					
					setOriginalWidth(wallImage.naturalWidth);
					setOriginalHeight(wallImage.naturalHeight);
					
					setCanvasWidth(newImgWidth);
					setCanvasHeight(canvas_height);
				}
				
			}
		}
	}, [wallImageData,]);

	// alert pills
	useEffect(() => {
		if (landmarkInfo && Object.keys(landmarkInfo) && landmarkInfo.coords && landmarkInfo.coords.length) {
			let pills = { stock: 0, zero_stock: 0, stockout: 0, spread_to_fill: 0, price_difference: 0, hua: 0, promotion: 0, misplaced_product: 0 };
			landmarkInfo.coords.forEach(coord => {
				if (coord.misplaced_product) pills.misplaced_product++
				else if (coord.stockout) pills.stockout++;
				else if (coord.spread_to_fill) pills.spread_to_fill++;
				else if (coord.price_difference) pills.price_difference++;
				else pills.stock++;
			})
			wallLandmarks.forEach(l => {
				pills.hua++;
			})
			aislePromotions.forEach(l => {
				pills.promotion++;
			})
			setAlertPills(pills);
		}
	}, [landmarkInfo]);

	// category filters
	useEffect(() => {
		if (landmarkInfo && Object.keys(landmarkInfo) && landmarkInfo.coords && landmarkInfo.coords.length) {
			let pills = [];
			landmarkInfo.coords.forEach(coord => {
				if (coord.name_category && !(pills.includes(coord.name_category))) {
					pills.push(coord.name_category);
				}
			})
			pills.sort((a, b) => { if (a > b) return 1; return -1; });
			setCategoryFilters(pills);
		}
	}, [landmarkInfo]);

	// scroll to landmark
	useEffect(() => {
		if (canvasWidth && originalWidth && selectedLandmark && selectedLandmark.pixel_x) {
			let w = document.getElementById('wallScroll');
			let scale_x = canvasWidth / originalWidth;
			w.scrollTo(selectedLandmark.pixel_x * scale_x - 30, 0);
		}
	}, [selectedLandmark, canvasWidth, originalWidth])

	// scroll to product
	useEffect(() => {
		if (canvasWidth && originalWidth && selectedProductState && Object.keys(selectedProductState).length > 0 && landmarkInfo?.coords?.length > 0) {
			for (let index = 0; index < landmarkInfo.coords.length; index++) {
				const coord = landmarkInfo.coords[index];
				if (coord?.item === selectedProductState?.item) {
					let w = document.getElementById('wallScroll');
					let scale_x = canvasWidth / originalWidth;
					let scrollValue = (
						coord?.pixel_z
							? coord.pixel_x
							: coord.facing_bottom_right_x
					) * scale_x;
					w.scrollTo(scrollValue - 30, 0);
					break;
				}
			}
		}
	}, [selectedProductState, landmarkInfo, canvasWidth, originalWidth])

	// img product bugs
	const handleShowInfo = () => {
		if (!zoom) {
			setZoom(!zoom);
		}
		setLandmarksVisible(!landmarksVisible);
	}

	// img zoom
	const handleZoom = () => {
		setLandmarksVisible(!landmarksVisible);

		setZoom(!zoom);
	}

	const zoomImageSquare = ({ originalWidth, originalHeight, mouseX, mouseY }) => {
		const viewerCanvasHeight = document.getElementById("wallScroll").offsetHeight;// s.windowHeight //insideWrapper.offsetHeight; // s.windowHeight - 413;
		const canvasWidth = document.getElementById('canvas').offsetWidth;
		const CENTER_Y = 10;

		const top = -(mouseY - CENTER_Y) * originalHeight / viewerCanvasHeight;
		const left = - (mouseX * originalWidth / canvasWidth) + 314 / 2; // 314 is the width of the Box wich contains the zoomed image
		const newStyle = { zIndex: 1, width: `${originalWidth}px`, height: `${originalHeight}px`, display: 'block', position: 'relative', top: `${top}px`, left: `${left}px` }
		setZoomedWallImageStyle(newStyle);


	}

	const moveTooltipCanvas = ({ mode, coord, isTooltipFixed = false }) => {
		const tooltipCanvas = document.getElementById('tooltipCanvas');
		const wallScroll = document.getElementById("wallScroll");
		if (mode === 'moveOn') {
			setIsTooltipFixed(isTooltipFixed);
			const scale_x = canvasWidth / originalWidth;
			const scale_y = canvasHeight / originalHeight;
			let xElement = wallScroll.offsetLeft;
			let yElement = wallScroll.offsetTop;
			let x = 0;
			if (coord.hasOwnProperty('pixel_z')) {
				xElement += coord.pixel_x * scale_x + 4;
				yElement += coord.pixel_z * scale_y + 4;
				x += coord.pixel_x * scale_x;
			} else {
				xElement += (coord.facing_bottom_right_x) * scale_x + 4;
				yElement += (coord.facing_bottom_right_y + coord.facing_top_left_y) / 2 * scale_y + 4;
				x += coord.facing_top_left_x * scale_x;
			}
			let newTop = `${yElement / canvasHeight * 270}px`;
			let newLeft = `${xElement + 70}px`;
			// despliegue card (izq/der):

			if (wallScroll.scrollLeft === 0 && wallScroll.offsetWidth / 2 < x) {
				newLeft = (x - 400).toString() + 'px'; // if we have troubles with the tooltipCanvas width, we can use this - wallScroll.scrollLeft).toString()
			}
			else {
				let newX = x - wallScroll.scrollLeft;
				if (wallScroll.offsetWidth / 2 < newX)
					newLeft = (newX - 400).toString() + 'px';
				else
					newLeft = (newX + tooltipCanvas.offsetWidth - 300).toString() + 'px';
			}

			tooltipCanvas.style.left = newLeft;
			tooltipCanvas.style.top = newTop;
			tooltipCanvas.style.opacity = '1';
			tooltipCanvas.style.zIndex = '100';

		} else if (mode === 'close') {
			setIsTooltipFixed(false);
			tooltipCanvas.style.opacity = '0';
			tooltipCanvas.style.zIndex = '-1';
		} else {
			tooltipCanvas.style.opacity = '0';
			tooltipCanvas.style.zIndex = '-1';
		}
	}

	const getMousePos = (event) => {
		const misplaced_bbox_canvas = document.getElementById('misplaced_bbox')
		const ctx = misplaced_bbox_canvas.getContext("2d");

		const x = event.clientX, y = event.clientY;
		let notFound = true;
		let coordsData = [...landmarkInfo.coords, ...aislePromotions, ...aisleLandmarks];
		if (coordsData && coordsData.length) {
			for (let idx = 0; idx < coordsData.length; idx++) {
				const coord = coordsData[idx];

				const scale_x = canvasWidth / originalWidth;
				const scale_y = canvasHeight / originalHeight;
				let px = 0, py = 0;
				let mouseIn = false, mouseInBBox = false;
				if (coord.hasOwnProperty('pixel_z')) {
					px = coord.pixel_x * scale_x + 4;
					py = coord.pixel_z * scale_y + 4;
					mouseIn = (px - RADIUSPLUS <= x) && (x <= px + RADIUSPLUS) && (py - RADIUSPLUS <= y) && (y <= py + RADIUSPLUS);
					mouseInBBox = (
						(coord.pixel_x * scale_x - RADIUSPLUS * 2) < x && x <= (coord.pixel_x * scale_x + RADIUSPLUS * 2)
						&& (coord.pixel_z * scale_y - RADIUSPLUS * 2) < y && y <= (coord.pixel_z * scale_y + RADIUSPLUS * 2)
					);
				} else {
					px = (coord.facing_bottom_right_x + coord.facing_top_left_x) / 2 * scale_x - RADIUSPLUS / 2;
					py = (coord.facing_bottom_right_y + coord.facing_top_left_y) / 2 * scale_y - RADIUSPLUS / 2;

					mouseIn = (px <= x) && (x <= px + RADIUSPLUS) && (py <= y) && (y <= py + RADIUSPLUS);
					mouseInBBox = (
						coord.facing_top_left_x * scale_x < x && x <= (coord.facing_bottom_right_x * scale_x)
						&& coord.facing_top_left_y * scale_y < y && y <= (coord.facing_bottom_right_y * scale_y)
					);
				}
				coord['isHoverOn'] = mouseIn || (mouseInBBox && coord.isHoverOn);
				if (mouseInBBox && isHoverOn.current && idx === selectedProductIndex.current) {
					notFound = false;
					isHoverOn.current = true;
					selectedProductIndex.current = idx;
					setSelectedProduct(coord);
					// move tooltip to near px, py
					moveTooltipCanvas({ mode: 'moveOn', coord });
					zoomImageSquare({ originalWidth, originalHeight, mouseX: x, mouseY: y });
					ctx.clearRect(0, 0, misplaced_bbox_canvas.width, misplaced_bbox_canvas.height);

					if (coord.misplaced_product) {

						coord.wall_bbox.forEach((bbox) => {
							ctx.beginPath();
							ctx.strokeStyle = colors['misplaced_product']['color'];
							ctx.lineWidth = 4
							ctx.moveTo(bbox[0][0], bbox[0][1]); // point 1
							ctx.lineTo(bbox[1][0], bbox[1][1]); // point 2
							ctx.lineTo(bbox[2][0], bbox[2][1]); // point 3
							ctx.lineTo(bbox[3][0], bbox[3][1]); // point 4
							ctx.closePath();      // go back to point 1
							ctx.stroke();         // draw stroke line
						})
					}
					break;
				}
				else if ((mouseIn) && (!isHoverOn.current || idx !== selectedProductIndex.current)) {
					notFound = false;
					isHoverOn.current = true;
					selectedProductIndex.current = idx;
					setSelectedProduct(coord);
					// move tooltip to near px, py
					moveTooltipCanvas({ mode: 'moveOn', coord });
					break;
				}
				else if (mouseIn && isHoverOn.current) {
					notFound = false;
					break;
				}
			}
		}
		if (notFound) {
			if (!isTooltipFixed) { moveTooltipCanvas({}); moveTooltipCanvas({ tooltipName: 'tooltipPromos' }); }
			setSelectedProduct({});
			isHoverOn.current = false;
		}
	}

	const handleCanvasHover = (event) => {
		getMousePos(event);
	}

	const handleExcelDownload = () => {
		setIsLoadingExcel(true);
		getExcelLandmarks(selectedStore?.store_code, selectedClient?.supplier_id);
	}

	const checkLandmarksAvailable = () => {
		if (landmarkInfo.coords && landmarkInfo.coords.length > 0) {
			return true;
		}
		return false;
	}

	useEffect(() => {
		if (!isLoadingLandmarksExcel && excelLandmarkList && excelLandmarkList.length > 0) {
			let data = [...excelLandmarkList];
			const landmarksTranslation = t('Chains.' + selectedStore?.chain_name + '.landmark');
			const aislesClientNames = aislesData.client_aisles_names;
			let dataToDownload = data.slice(1);
			let columnsTranslated = data[0].map(col => getChainMeaning(t, selectedStore.chain_name, col));
			columnsTranslated[5] = (columnsTranslated[5].split('landmark')[1] && landmarksTranslation)
				? `${landmarksTranslation}${columnsTranslated[5].split('landmark')[1]}`
				: columnsTranslated[5]; // landmark_robot translate
			// Translate aisle column
			if (columnsTranslated.includes("aisle")) {
				const aisleIndex = columnsTranslated.indexOf("aisle");
				dataToDownload.forEach(row => {
					const aislesClientName = aislesClientNames[row[aisleIndex]];
					row[aisleIndex] = aislesClientName ? aislesClientNames[row[aisleIndex]] : row[aisleIndex];
				})
			}
			const filename = `landmarks ${selectedStore?.store_code}-${dateState.toLocaleDateString().replaceAll("/", "-")}`;
			getDigitalShelfExcel({ excelData: [columnsTranslated, ...dataToDownload], excelFileName: filename });
			setIsLoadingExcel(false);
		}
	}, [excelLandmarkList]);

	useEffect(() => {
		clearCoordsState();
		clearWallImageData();
	}, [selectedStore]);


	return (
		<>
			<Grid item container
				id="wallScroll"
				direction={'row'}
				spacing={{ xs: 2, md: 3 }}
				columns={{ xs: 2, sm: 8, md: 12 }}
				style={{
					backgroundColor: selectedAisle && Object.keys(selectedAisle).length > 0 ? '#1a242d' : '',
					WebkitOverflowScrolling: 'touch !important',
					'height': 'max-content',
					minHeight: '400px',
					maxHeight: '500px',
					width: '100%',
					minWidth: '450px',
					overflowX: 'auto',
					overflowY: 'hidden',
					marginTop: '1em',
					marginLeft: '0.1em',
					position: 'relative',
				}}
			>
				{(!isLoadingWallImage && Object.keys(wallImageData).length > 0) //image loaded
					&& !isLoadingCoordsInfo ?
					<>
						{' '}
						{zoom ?
							<>{/* bug drawer wall canvas */}
								<WallDrawer
									aisle={selectedAisle}
									landmark={selectedLandmark}
									png={wallImageData.signed_url}
									imageMetadata={wallImage}
									coords={landmarkInfo.coords}
									wallLandmarks={wallLandmarks}
									canvasHeight={canvasHeight}
									canvasWidth={canvasWidth}
									originalWidth={originalWidth}
									originalHeight={originalHeight}
									landmarksVisible={landmarksVisible}
									selectedStatus={selectedStatus}
									selectedCategories={selectedCategories}
									colors={colors}
									product={selectedProductState}
									handleCanvasHover={handleCanvasHover}
									aislePromotions={aislePromotions}
									aisleLandmarks={aisleLandmarks}
									selectedAisle={selectedAisle}
									selectedStore={selectedStore}
								/>
							</>
							:
							<TransformWrapper>
								<TransformComponent>
									<img
										alt=""
										height={canvasHeight}
										width={canvasWidth}
										src={wallImageData.signed_url}
									/>
								</TransformComponent>
							</TransformWrapper>
						} </> : null
				}
			</Grid>
			{zoom ?
				<Paper id="tooltipCanvas"
							 sx={{
								 display: 'overflow',
								 position: 'absolute',
								 color: colorBasedOnTheme(theme, 'color'),
								 backgroundColor: theme.palette.paper.main,
								 width: 400,
								 height: 400,
								 opacity: 0,
								 zIndex: -1,
								 borderRadius: '2px',
								 overflowY: 'hidden',
							 }}
				>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', p: 1, }}>
						<CardContent sx={{ py: '2px' }}>
							<Typography gutterBottom variant="body1" component="div" sx={{ fontWeight: 'bold' }}>
								{selectedProduct?.texts ?
									selectedProduct?.texts
									: selectedProduct?.landmark ?
										selectedProduct?.landmark
										: selectedProduct.description}
							</Typography>
						</CardContent>
						<Box sx={{ overflow: 'hidden', maxHeight: '178px', borderRadius: '5px', width: '100%' }}>
							<Box sx={zoomedWallImageStyle}>
								<img alt="" id="zoomedWallImage" src={wallImageData.signed_url} />
								<canvas id='misplaced_bbox' width="5000" height="5000" style={{ position: 'absolute', top: 0, left: 0 }} />
							</Box>
						</Box>
						<CustomCardContent selectedProduct={selectedProduct} selectedStore={selectedStore} dateState={dateState} misplacedSignedImages={misplacedSignedImages}
															 loadingMisplacedSignedImages={loadingMisplacedSignedImages} getMisplacedProductSignedImages={getMisplacedProductSignedImages} />
					</Box>
				</Paper>
				: null
			}
		</>
	);
}

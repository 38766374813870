import hd0121JSON from '../../../assets/maps/HD0121_points.json';
import hd0121JSON_AISLE from '../../../assets/maps/HD0121_points_aisle.json';
import hd0121JSON_AISLE_2 from '../../../assets/maps/HD0121_points_aisle_2.json';
import hd0121JSON_AISLE_3 from '../../../assets/maps/HD0121_points_aisle_3.json';
import hd0121JSON_AISLE_4 from '../../../assets/maps/HD0121_points_aisle_4.json';

const aisles =
{
    "BIO3": {
        "px_init": [
            669,
            787
        ],
        "px_end": [
            805,
            876
        ],
        "meter_init": [
            125.6,
            53.7
        ],
        "meter_end": [
            118.75,
            58.15
        ]
    },
    "17B": {
        "px_init": [
            128,
            1546
        ],
        "px_end": [
            552,
            1641
        ],
        "meter_init": [
            131.4,
            20.2
        ],
        "meter_end": [
            152.6,
            15.45
        ]
    },
    "23A": {
        "px_init": [
            1539,
            1065
        ],
        "px_end": [
            1652,
            1480
        ],
        "meter_init": [
            76.4,
            23.5
        ],
        "meter_end": [
            82.05,
            44.25
        ]
    },
    "21A": {
        "px_init": [
            1330,
            1081
        ],
        "px_end": [
            1447,
            1480
        ],
        "meter_init": [
            86.65,
            23.5
        ],
        "meter_end": [
            92.5,
            43.45
        ]
    },
    "27B": {
        "px_init": [
            1985,
            601
        ],
        "px_end": [
            2048,
            996
        ],
        "meter_init": [
            59.75,
            67.45
        ],
        "meter_end": [
            56.6,
            47.7
        ]
    },
    "MUR": {
        "px_init": [
            36,
            967
        ],
        "px_end": [
            130,
            1487
        ],
        "meter_init": [
            157.2,
            23.15
        ],
        "meter_end": [
            152.5,
            49.15
        ]
    },
    "16B1": {
        "px_init": [
            103,
            1427
        ],
        "px_end": [
            534,
            1490
        ],
        "meter_init": [
            153.85,
            26.15
        ],
        "meter_end": [
            132.3,
            23
        ]
    },
    "2A": {
        "px_init": [
            846,
            606
        ],
        "px_end": [
            902,
            985
        ],
        "meter_init": [
            116.7,
            67.2
        ],
        "meter_end": [
            113.9,
            48.25
        ]
    },
    "BIO5": {
        "px_init": [
            669,
            628
        ],
        "px_end": [
            802,
            713
        ],
        "meter_init": [
            125.55,
            61.85
        ],
        "meter_end": [
            118.9,
            66.1
        ]
    },
    "BIO4": {
        "px_init": [
            669,
            711
        ],
        "px_end": [
            801,
            790
        ],
        "meter_init": [
            125.55,
            58
        ],
        "meter_end": [
            118.95,
            61.95
        ]
    },
    "5B": {
        "px_init": [
            107,
            618
        ],
        "px_end": [
            522,
            691
        ],
        "meter_init": [
            153.65,
            66.6
        ],
        "meter_end": [
            132.9,
            62.95
        ]
    },
    "5C": {
        "px_init": [
            2052,
            905
        ],
        "px_end": [
            2225,
            975
        ],
        "meter_init": [
            56.4,
            48.75
        ],
        "meter_end": [
            47.75,
            52.25
        ]
    },
    "4A": {
        "px_init": [
            954,
            606
        ],
        "px_end": [
            1017,
            989
        ],
        "meter_init": [
            108.15,
            67.2
        ],
        "meter_end": [
            111.3,
            48.1
        ]
    },
    "14B": {
        "px_init": [
            101,
            1296
        ],
        "px_end": [
            534,
            1358
        ],
        "meter_init": [
            153.95,
            32.7
        ],
        "meter_end": [
            132.3,
            29.6
        ]
    },
    "11B": {
        "px_init": [
            100,
            1024
        ],
        "px_end": [
            524,
            1080
        ],
        "meter_init": [
            154,
            46.35
        ],
        "meter_end": [
            132.8,
            43.5
        ]
    },
    "13B1": {
        "px_init": [
            100,
            1154
        ],
        "px_end": [
            532,
            1220
        ],
        "meter_init": [
            154,
            39.85
        ],
        "meter_end": [
            132.4,
            36.5
        ]
    },
    "12B": {
        "px_init": [
            99,
            1080
        ],
        "px_end": [
            532,
            1154
        ],
        "meter_init": [
            154.05,
            39.8
        ],
        "meter_end": [
            132.4,
            43.5
        ]
    },
    "13C2": {
        "px_init": [
            2167,
            305
        ],
        "px_end": [
            2214,
            511
        ],
        "meter_init": [
            50.7,
            71.95
        ],
        "meter_end": [
            48.3,
            82.25
        ]
    },
    "13C1": {
        "px_init": [
            2232,
            320
        ],
        "px_end": [
            2295,
            616
        ],
        "meter_init": [
            44.25,
            81.5
        ],
        "meter_end": [
            47.45,
            66.7
        ]
    },
    "9C": {
        "px_init": [
            2056,
            1338
        ],
        "px_end": [
            2229,
            1404
        ],
        "meter_init": [
            56.2,
            27.3
        ],
        "meter_end": [
            47.55,
            30.6
        ]
    },
    "8C2": {
        "px_init": [
            2041,
            1164
        ],
        "px_end": [
            2239,
            1256
        ],
        "meter_init": [
            56.95,
            34.7
        ],
        "meter_end": [
            47.05,
            39.35
        ]
    },
    "6C": {
        "px_init": [
            2055,
            977
        ],
        "px_end": [
            2227,
            1090
        ],
        "meter_init": [
            56.25,
            43
        ],
        "meter_end": [
            47.7,
            48.65
        ]
    },
    "3B": {
        "px_init": [
            102,
            483
        ],
        "px_end": [
            403,
            552
        ],
        "meter_init": [
            153.9,
            73.35
        ],
        "meter_end": [
            138.85,
            69.9
        ]
    },
    "14E": {
        "px_init": [
            2162,
            311
        ],
        "px_end": [
            2287,
            363
        ],
        "meter_init": [
            44.65,
            81.95
        ],
        "meter_end": [
            50.95,
            79.35
        ]
    },
    "11DS": {
        "px_init": [
            2312,
            645
        ],
        "px_end": [
            2381,
            1139
        ],
        "meter_init": [
            39.95,
            65.25
        ],
        "meter_end": [
            43.4,
            40.55
        ]
    },
    "16B2": {
        "px_init": [
            142,
            1490
        ],
        "px_end": [
            532,
            1549
        ],
        "meter_init": [
            151.9,
            23
        ],
        "meter_end": [
            132.4,
            20.05
        ]
    },
    "1C": {
        "px_init": [
            2051,
            627
        ],
        "px_end": [
            2228,
            706
        ],
        "meter_init": [
            56.45,
            62.2
        ],
        "meter_end": [
            47.6,
            66.15
        ]
    },
    "BIO": {
        "px_init": [
            667,
            950
        ],
        "px_end": [
            805,
            1004
        ],
        "meter_init": [
            125.65,
            50
        ],
        "meter_end": [
            118.75,
            47.3
        ]
    },
    "19A": {
        "px_init": [
            1111,
            1086
        ],
        "px_end": [
            1198,
            1480
        ],
        "meter_init": [
            99.1,
            23.5
        ],
        "meter_end": [
            103.45,
            43.2
        ]
    },
    "20A": {
        "px_init": [
            1197,
            1081
        ],
        "px_end": [
            1332,
            1480
        ],
        "meter_init": [
            92.45,
            23.5
        ],
        "meter_end": [
            99.15,
            43.45
        ]
    },
    "13B2": {
        "px_init": [
            100,
            1221
        ],
        "px_end": [
            531,
            1294
        ],
        "meter_init": [
            154,
            32.8
        ],
        "meter_end": [
            132.45,
            36.45
        ]
    },
    "14A": {
        "px_init": [
            1673,
            606
        ],
        "px_end": [
            1750,
            995
        ],
        "meter_init": [
            71.5,
            67.2
        ],
        "meter_end": [
            75.35,
            47.75
        ]
    },
    "5DS": {
        "px_init": [
            2733,
            652
        ],
        "px_end": [
            2815,
            1627
        ],
        "meter_init": [
            22.35,
            16.15
        ],
        "meter_end": [
            18.25,
            64.9
        ]
    },
    "BIO2": {
        "px_init": [
            666,
            875
        ],
        "px_end": [
            804,
            951
        ],
        "meter_init": [
            125.7,
            49.95
        ],
        "meter_end": [
            118.8,
            53.8
        ]
    },
    "4C": {
        "px_init": [
            2052,
            838
        ],
        "px_end": [
            2223,
            908
        ],
        "meter_init": [
            56.4,
            52.1
        ],
        "meter_end": [
            47.85,
            55.6
        ]
    },
    "8B": {
        "px_init": [
            100,
            826
        ],
        "px_end": [
            522,
            903
        ],
        "meter_init": [
            154,
            52.35
        ],
        "meter_end": [
            132.9,
            56.2
        ]
    },
    "1DS": {
        "px_init": [
            3041,
            660
        ],
        "px_end": [
            3108,
            1626
        ],
        "meter_init": [
            6.95,
            64.5
        ],
        "meter_end": [
            3.6,
            16.2
        ]
    },
    "3DS": {
        "px_init": [
            2889,
            645
        ],
        "px_end": [
            2978,
            1642
        ],
        "meter_init": [
            14.55,
            15.4
        ],
        "meter_end": [
            10.15,
            65.25
        ]
    },
    "6A": {
        "px_init": [
            1094,
            615
        ],
        "px_end": [
            1165,
            994
        ],
        "meter_init": [
            100.75,
            66.75
        ],
        "meter_end": [
            104.3,
            47.8
        ]
    },
    "7B": {
        "px_init": [
            111,
            762
        ],
        "px_end": [
            502,
            827
        ],
        "meter_init": [
            153.45,
            59.4
        ],
        "meter_end": [
            133.9,
            56.15
        ]
    },
    "13A": {
        "px_init": [
            1597,
            608
        ],
        "px_end": [
            1673,
            997
        ],
        "meter_init": [
            75.35,
            67.1
        ],
        "meter_end": [
            79.15,
            47.65
        ]
    },
    "18A": {
        "px_init": [
            1021,
            1092
        ],
        "px_end": [
            1111,
            1482
        ],
        "meter_init": [
            103.45,
            23.4
        ],
        "meter_end": [
            107.95,
            42.9
        ]
    },
    "22A": {
        "px_init": [
            1447,
            1081
        ],
        "px_end": [
            1539,
            1480
        ],
        "meter_init": [
            82.05,
            23.5
        ],
        "meter_end": [
            86.7,
            43.45
        ]
    },
    "11A": {
        "px_init": [
            1454,
            615
        ],
        "px_end": [
            1532,
            992
        ],
        "meter_init": [
            82.45,
            66.75
        ],
        "meter_end": [
            86.3,
            47.9
        ]
    },
    "8DS": {
        "px_init": [
            2507,
            651
        ],
        "px_end": [
            2587,
            1629
        ],
        "meter_init": [
            33.65,
            16.05
        ],
        "meter_end": [
            29.65,
            64.95
        ]
    },
    "6DS": {
        "px_init": [
            2654,
            660
        ],
        "px_end": [
            2748,
            1617
        ],
        "meter_init": [
            26.3,
            64.5
        ],
        "meter_end": [
            21.6,
            16.65
        ]
    },
    "27A": {
        "px_init": [
            1981,
            1074
        ],
        "px_end": [
            2056,
            1495
        ],
        "meter_init": [
            59.95,
            43.8
        ],
        "meter_end": [
            56.2,
            22.75
        ]
    },
    "2B": {
        "px_init": [
            101,
            411
        ],
        "px_end": [
            401,
            483
        ],
        "meter_init": [
            153.95,
            73.35
        ],
        "meter_end": [
            138.95,
            76.95
        ]
    },
    "7DS": {
        "px_init": [
            2572,
            654
        ],
        "px_end": [
            2656,
            1125
        ],
        "meter_init": [
            30.4,
            41.25
        ],
        "meter_end": [
            26.2,
            64.8
        ]
    },
    "2DS": {
        "px_init": [
            2963,
            645
        ],
        "px_end": [
            3045,
            1645
        ],
        "meter_init": [
            10.9,
            15.25
        ],
        "meter_end": [
            6.75,
            65.25
        ]
    },
    "3C": {
        "px_init": [
            2054,
            771
        ],
        "px_end": [
            2224,
            840
        ],
        "meter_init": [
            56.3,
            55.5
        ],
        "meter_end": [
            47.8,
            58.95
        ]
    },
    "4DS": {
        "px_init": [
            2808,
            653
        ],
        "px_end": [
            2896,
            1629
        ],
        "meter_init": [
            18.6,
            16.05
        ],
        "meter_end": [
            14.2,
            64.85
        ]
    },
    "6B": {
        "px_init": [
            109,
            691
        ],
        "px_end": [
            521,
            763
        ],
        "meter_init": [
            153.55,
            62.95
        ],
        "meter_end": [
            132.95,
            59.35
        ]
    },
    "7C": {
        "px_init": [
            2053,
            1090
        ],
        "px_end": [
            2228,
            1161
        ],
        "meter_init": [
            47.6,
            39.45
        ],
        "meter_end": [
            56.35,
            43
        ]
    },
    "1B": {
        "px_init": [
            138,
            332
        ],
        "px_end": [
            400,
            413
        ],
        "meter_init": [
            152.1,
            76.85
        ],
        "meter_end": [
            139,
            80.9
        ]
    },
    "5A": {
        "px_init": [
            1017,
            605
        ],
        "px_end": [
            1095,
            997
        ],
        "meter_init": [
            104.25,
            67.25
        ],
        "meter_end": [
            108.15,
            47.65
        ]
    },
    "16A": {
        "px_init": [
            1823,
            604
        ],
        "px_end": [
            1897,
            994
        ],
        "meter_init": [
            64.15,
            67.3
        ],
        "meter_end": [
            67.85,
            47.8
        ]
    },
    "10A": {
        "px_init": [
            1382,
            615
        ],
        "px_end": [
            1453,
            999
        ],
        "meter_init": [
            86.35,
            66.75
        ],
        "meter_end": [
            89.95,
            47.6
        ]
    },
    "10B": {
        "px_init": [
            98,
            966
        ],
        "px_end": [
            522,
            1024
        ],
        "meter_init": [
            154.1,
            49.2
        ],
        "meter_end": [
            132.9,
            46.35
        ]
    },
    "12A": {
        "px_init": [
            1532,
            611
        ],
        "px_end": [
            1597,
            997
        ],
        "meter_init": [
            79.15,
            66.95
        ],
        "meter_end": [
            82.45,
            47.65
        ]
    },
    "24A": {
        "px_init": [
            1648,
            1066
        ],
        "px_end": [
            1765,
            1479
        ],
        "meter_init": [
            76.6,
            23.6
        ],
        "meter_end": [
            70.75,
            44.2
        ]
    },
    "28A": {
        "px_init": [
            2220,
            600
        ],
        "px_end": [
            2288,
            1497
        ],
        "meter_init": [
            44.6,
            22.65
        ],
        "meter_end": [
            48,
            67.5
        ]
    },
    "BIO6": {
        "px_init": [
            669,
            572
        ],
        "px_end": [
            802,
            630
        ],
        "meter_init": [
            125.6,
            66
        ],
        "meter_end": [
            118.9,
            68.9
        ]
    },
    "17A": {
        "px_init": [
            1897,
            602
        ],
        "px_end": [
            1985,
            994
        ],
        "meter_init": [
            59.75,
            67.4
        ],
        "meter_end": [
            64.2,
            47.8
        ]
    },
    "2C": {
        "px_init": [
            2052,
            705
        ],
        "px_end": [
            2222,
            773
        ],
        "meter_init": [
            56.4,
            58.85
        ],
        "meter_end": [
            47.9,
            62.25
        ]
    },
    "7A": {
        "px_init": [
            1163,
            614
        ],
        "px_end": [
            1238,
            995
        ],
        "meter_init": [
            97.1,
            66.8
        ],
        "meter_end": [
            100.85,
            47.75
        ]
    },
    "9B": {
        "px_init": [
            99,
            905
        ],
        "px_end": [
            523,
            965
        ],
        "meter_init": [
            154.05,
            52.3
        ],
        "meter_end": [
            132.85,
            49.25
        ]
    },
    "1A": {
        "px_init": [
            806,
            608
        ],
        "px_end": [
            847,
            1001
        ],
        "meter_init": [
            116.65,
            67.1
        ],
        "meter_end": [
            118.7,
            47.45
        ]
    },
    "9DS": {
        "px_init": [
            2436,
            646
        ],
        "px_end": [
            2515,
            1626
        ],
        "meter_init": [
            37.2,
            16.2
        ],
        "meter_end": [
            33.25,
            65.2
        ]
    },
    "3A": {
        "px_init": [
            897,
            609
        ],
        "px_end": [
            954,
            984
        ],
        "meter_init": [
            111.3,
            67.05
        ],
        "meter_end": [
            114.15,
            48.35
        ]
    },
    "10C": {
        "px_init": [
            2055,
            1403
        ],
        "px_end": [
            2232,
            1469
        ],
        "meter_init": [
            56.25,
            24.05
        ],
        "meter_end": [
            47.4,
            27.35
        ]
    },
    "15B": {
        "px_init": [
            101,
            1358
        ],
        "px_end": [
            536,
            1426
        ],
        "meter_init": [
            153.95,
            29.6
        ],
        "meter_end": [
            132.2,
            26.2
        ]
    },
    "8C1": {
        "px_init": [
            2045,
            1254
        ],
        "px_end": [
            2239,
            1340
        ],
        "meter_init": [
            56.75,
            30.5
        ],
        "meter_end": [
            47.05,
            34.8
        ]
    },
    "4B": {
        "px_init": [
            104,
            552
        ],
        "px_end": [
            404,
            619
        ],
        "meter_init": [
            153.8,
            69.9
        ],
        "meter_end": [
            138.8,
            66.55
        ]
    },
    "8A": {
        "px_init": [
            1234,
            612
        ],
        "px_end": [
            1310,
            995
        ],
        "meter_init": [
            93.5,
            66.9
        ],
        "meter_end": [
            97.3,
            47.75
        ]
    },
    "9A": {
        "px_init": [
            1309,
            611
        ],
        "px_end": [
            1384,
            992
        ],
        "meter_init": [
            89.8,
            66.95
        ],
        "meter_end": [
            93.55,
            47.9
        ]
    },
    "15A": {
        "px_init": [
            1750,
            606
        ],
        "px_end": [
            1823,
            994
        ],
        "meter_init": [
            67.85,
            67.2
        ],
        "meter_end": [
            71.55,
            47.8
        ]
    },
    "10DS": {
        "px_init": [
            2385,
            651
        ],
        "px_end": [
            2441,
            1609
        ],
        "meter_init": [
            39.76,
            17.07
        ],
        "meter_end": [
            36.96,
            64.97
        ]
    }
}

const bayAisles = [
    {
        "bay": "1",
        "aisle": "BIO3",
        "main_category": 0,
        "category_0": 1,
        "category": "Lunch",
        "px_init": [
            669,
            787
        ],
        "px_end": [
            805,
            876
        ],
    },
    {
        "bay": "1",
        "aisle": "17B",
        "main_category": 0,
        "category_0": 1,
        "category": "Lunch",
        "px_init": [
            128,
            1546
        ],
        "px_end": [
            552,
            1641
        ],
    },
    {
        "bay": "1",
        "aisle": "23A",
        "main_category": 0,
        "category_0": 3,
        "category": "Hardware",
        "px_init": [
            1539,
            1065
        ],
        "px_end": [
            1652,
            1480
        ],
    },
    {
        "bay": "1",
        "aisle": "21A",
        "main_category": 0,
        "category_0": 10,
        "category": "House",
        "px_init": [
            1330,
            1081
        ],
        "px_end": [
            1447,
            1480
        ],
    },
    {
        "bay": "2",
        "aisle": "21A",
        "main_category": 0,
        "category_0": 10,
        "category": "House",
        "px_init": [
            1450,
            1081
        ],
        "px_end": [
            1459,
            1480
        ],
    },
    // {
    //     "bay": "2",
    //     "aisle": "Inicio",
    //     "main_category": 0,
    //     "category_0": 3,
    //     "category": "House",
    //     "px_init": [
    //         1330,
    //         1324
    //     ],
    //     "px_end": [
    //         1690,
    //         1350
    //     ],
    // },
    // {
    //     "bay": "2",
    //     "aisle": "Fin",
    //     "main_category": 0,
    //     "category_0": 1,
    //     "category": "House",
    //     "px_init": [
    //         1430,
    //         1390
    //     ],
    //     "px_end": [
    //         1690,
    //         1420
    //     ],
    // },
]

const HD0121_points = {
    // ...hd0121JSON
    ...hd0121JSON_AISLE_4,
}

export {
    aisles,
    bayAisles,
    HD0121_points
}

export function userInfo({ session: { userInfo } }) {
  return userInfo;
}

export function isLoadingSession({ session: { loading } }) {
  return loading;
}

export function getUserInfo({ session: { userInfo } }) {
  return userInfo;
}

export function hasSession({ session: { loggedIn } }) {
  return loggedIn;
}


// Walls
export function getWallsAislesData({ wall: { aislesData = [] } }) {
  return aislesData;
}

export function isLoadingAislesData({ wall: { loadingAisles } }) {
  return loadingAisles;
}

export function getWallImageData({ wall: { wallImageData } }) {
  return wallImageData;
}

export function isLoadingWallImageData({ wall: { loadingWallImageData } }) {
  return loadingWallImageData;
}

export function getWallLandmarks({ wall: { wallLandmarksData } }) {
  return wallLandmarksData;
}

export function isLoadingWallLandmarks({ wall: { loadingWallLandmarksData } }) {
  return loadingWallLandmarksData;
}

export function getAllLandmarks({ wall: { landmarkData } }) {
  return landmarkData;
}

export function isLoadingLandmark({ wall: { loadingLandmarkData } }) {
  return loadingLandmarkData;
}

export function getCoordsInfo({ wall: { coordsInfo } }) {
  return coordsInfo;
}

export function isLoadingCoordsInfo({ wall: { loadingCoordsInfo } }) {
  return loadingCoordsInfo;
}

export function misplacedSignedImages({ wall: { misplacedSignedImages } }) {
  return misplacedSignedImages;
}

export function loadingMisplacedSignedImages({ wall: { loadingMisplacedSignedImages } }) {
  return loadingMisplacedSignedImages;
}

export function getProductSearchResults({ wall: { searchResults } }) {
  return searchResults;
}

export function isLoadingProductSearchResults({
                                                wall: { loadingSearchResults },
                                              }) {
  return loadingSearchResults;
}

export function getSelectedWallStore({ wall: { selectedStore } }) {
  return selectedStore;
}

export function getSelectedWallAisle({ wall: { selectedAisle } }) {
  return selectedAisle;
}

export function getSelectedWallLandmark({ wall: { selectedLandmark } }) {
  return selectedLandmark;
}

export function getSelectedWallDate({ wall: { selectedDate } }) {
  return selectedDate;
}

export function getSelectedWallProduct({ wall: { selectedProduct } }) {
  return selectedProduct;
}

export function getExcelLandmarkList({ wall: { excelLandmarks } }) {
  return excelLandmarks;
}

export function isLoadingLandmarksExcel({ wall: { loadingLandmarksExcel } }) {
  return loadingLandmarksExcel;
}
export function getAislePromotionsList({ wall: { aislePromotions } }) {
  return aislePromotions;
}

export function isLoadingAislePromotions({ wall: { loadingAislePromotions } }) {
  return loadingAislePromotions;
}

export function getAisleLandmarkState({ wall: { aisleLandmarks } }) {
  return aisleLandmarks;
}


import React from 'react'

export default function Box(props) {
    const RAD_TRANSFORM = Math.PI / 180;
    const {
        scale = 0.8,
        // x, y, z
        position = [0, 0, 0],
        // down around x, right around y, left around z
        rotation = [0 * RAD_TRANSFORM, 0 * RAD_TRANSFORM, 0 * RAD_TRANSFORM],
        // width, length, deep
        boxSizes = [3, 1, 8],
        color = 'orange',
        ...meshProps
    } = props;

    return (
        <mesh
            {...meshProps}
            scale={scale}
            position={position}
            rotation={rotation}
        >
            <boxGeometry args={boxSizes} />
            <meshStandardMaterial color={color} />
        </mesh>
    )
}

import { Text } from '@react-three/drei'

const textProps = {
    fontSize: 0.2,
    // font: 'http://fonts.gstatic.com/s/modak/v5/EJRYQgs1XtIEskMA-hI.woff'
}

export default function TextCanvas(props) {
    const {
        position = [0, 0, 0],
        text = 'A-1',
        rotation = [0 * Math.PI / 180, 0 * Math.PI / 180, 0 * Math.PI / 180]
    } = props
    return (
        <group name="sceneContainer">
            <Text color="black" depthTest={false}
                material-toneMapped={false}
                name={'titleCopy-'}
                text={text}
                {...textProps}
                rotation={rotation}
                position={position}>
                {text}
            </Text>
        </group>
    )
}

import { connect } from 'react-redux';
import Wall from '../../../../../src/app/components/geometry/ShelfModal/digitalShelf/Wall';


import {
    setSelectedStore,
    setAisleState,
    setLandmarkState,
    setDateState,
    setProductState,
    getExcelLandmarks,
    getDigitalShelfExcel,
    clearCoordsState,
    clearWallImageData,
    getMisplacedProductSignedImages
} from 'core/slices/wall';

import {
    getWallImageData,
    getCoordsInfo,
    getWallLandmarks,
    getWallsAislesData,
    getAislePromotionsList,

    isLoadingAislePromotions,
    isLoadingWallImageData,
    isLoadingCoordsInfo,
    isLoadingWallLandmarks,
    isLoadingLandmarksExcel,

    getSelectedWallStore,
    getSelectedWallAisle,
    getSelectedWallLandmark,
    getExcelLandmarkList,
    getSelectedWallDate,
    getSelectedWallProduct,
    getAisleLandmarkState,
    misplacedSignedImages,
    loadingMisplacedSignedImages
} from 'core/selectors';

export default connect(state => ({
    wallImageData: getWallImageData(state),
    isLoadingWallImage: isLoadingWallImageData(state),
    landmarkInfo: getCoordsInfo(state),
    isLoadingCoordsInfo: isLoadingCoordsInfo(state),
    wallLandmarks: getWallLandmarks(state),
    isLoadingLandmarks: isLoadingWallLandmarks(state),
    selectedStore: getSelectedWallStore(state),
    selectedAisle: getSelectedWallAisle(state),
    selectedLandmark: getSelectedWallLandmark(state),
    dateState: getSelectedWallDate(state),
    selectedProductState: getSelectedWallProduct(state),
    excelLandmarkList: getExcelLandmarkList(state),
    isLoadingLandmarksExcel: isLoadingLandmarksExcel(state),
    aislesData: getWallsAislesData(state),
    aislePromotions: getAislePromotionsList(state),
    isLoadingAislePromotions: isLoadingAislePromotions(state),
    aisleLandmarks: getAisleLandmarkState(state),
    misplacedSignedImages: misplacedSignedImages(state),
    loadingMisplacedSignedImages: loadingMisplacedSignedImages(state)

}), {
    setSelectedStore,
    setAisleState,
    setLandmarkState,
    setDateState,
    setProductState,
    getExcelLandmarks,
    getDigitalShelfExcel,
    clearCoordsState,
    clearWallImageData,
    getMisplacedProductSignedImages
})(Wall);

import React, { Fragment, Suspense } from 'react';
import {
  OrbitControls, PerspectiveCamera
} from "@react-three/drei";
import { Canvas } from '@react-three/fiber';

import Box from './Boxes';

import { getAisleCoords, getAisleBayCoords } from './map'
import TextCanvas from './text';

const WIDTH_CANVAS = 1000;
const HEIGHT_CANVAS = 800;

export default function Home(props) {
  const RAD_TRANSFORM = Math.PI / 180;
  // red, green, blue
  const rot_angle_x = 0 * RAD_TRANSFORM // 20
  const rot_angle_y = 10 * RAD_TRANSFORM // -25
  const rot_angle_z = 0 * RAD_TRANSFORM // -5
  const aisles = [
    {
      aisleName: 'A1',
      color: "red",
      position: [-4, 0, 4],
      rotation: [0, 0, 0],
      boxSizes: [8, 1, 3],
    },
    {
      aisleName: 'A1',
      color: "red",
      position: [-4, 0, 6],
      rotation: [0, 0, 0],
      boxSizes: [8, 1, 3],
    },
    {
      aisleName: 'A1',
      color: "red",
      position: [-4, 0, 8],
      rotation: [0, 0, 0],
      boxSizes: [8, 1, 3],
    },
    {
      aisleName: 'A1',
      color: "red",
      position: [0, 0, 9],
      rotation: [0, 0, 0],
      boxSizes: [3, 1, 8],
    },
  ]

  // const j510_aisles = getAisleCoords(WIDTH_CANVAS, HEIGHT_CANVAS)
  const j510_aisles = getAisleBayCoords(WIDTH_CANVAS, HEIGHT_CANVAS)
  // console.log('j510_aisles: ', j510_aisles);

  return (
    <Canvas style={{ width: `${WIDTH_CANVAS}px`, height: `${HEIGHT_CANVAS}px` }} camera={{
      position: [12, 7, 12], fov: 75
    }}>
      <OrbitControls enableZoom={true} />
      {/* Axes */}
      <axesHelper args={[5]} />

      {/* lights */}
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 20, 10]} intensity={1} />

      {/* camera */}
      <PerspectiveCamera
        position={[140, 4, 4]} lookAt={[0, 1, 0]}
        fov={45} // field of view in degrees
        // aspect={window.innerWidth / window.innerHeight} // aspect ratio
        aspect={WIDTH_CANVAS / HEIGHT_CANVAS} // aspect ratio
        near={1} far={100} />

      <Suspense fallback={null}>
        {/* {
          aisles.map(aisle => (
            <Box scale={0.4} color={aisle.color} position={aisle.position} rotation={aisle.rotation} boxSizes={aisle.boxSizes} />
          ))
        } */}
        {
          j510_aisles.map((aisle, index) => {
            const boxSizes = aisle.boxSizes;
            const cartel_pos = [aisle.position[0], 1, aisle.position[2]]
            return (
              <Fragment key={`${index}-box-home`}>
                <Box scale={0.4} color={aisle.color} position={aisle.position} rotation={aisle.rotation} boxSizes={boxSizes} />
                <Box scale={0.4} color={"#FFFFFF"} rotation={[0, 0, 0]}
                  position={cartel_pos} boxSizes={[2, 1, 0.2]} />
                <TextCanvas position={[cartel_pos[0], cartel_pos[1], cartel_pos[2] + 0.05]} text={aisle.aisleName}></TextCanvas>
              </Fragment>
            )
          })
        }
      </Suspense>
    </Canvas>


  )
}
